import { useRef, useState } from 'react'
import FlexComponent from '../../FlexComponent'
import { useNavigate } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import UserActivity from './UserActivity';



function UserPopup({ applicantData, pipelineId = 1, usersList, userId, setBoardSections, handleClose, toast }) {
    const navigate = useNavigate()

    const redirectProfilePage = (contact_id, user_id) => {
        navigate(`/app/${contact_id}/${user_id}`)
    }

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }
    

    return (
        <div >
            <FlexComponent justifyContent="start" alignItems="start" gap="3rem" className="applicantContainer">
                <FlexComponent className="applicantContainer" style={{ width: '100%' }} flexDirection="column" gap="1.5rem">
                    <FlexComponent className="applicantContainer" gap="1rem" >
                        <div className="applicantDivs" style={{ width: '30%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Name</div>
                            <div>{applicantData?.full_name}</div>
                        </div>
                        <div className="applicantDivs" style={{ width: '30%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Email</div>
                            <div>{applicantData?.email}</div>
                        </div>
                        <div className="applicantDivs" style={{ width: '30%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Phone</div>
                            <div>{applicantData?.phone}</div>
                        </div>
                    </FlexComponent>
                    <FlexComponent gap="1rem" className="applicantContainer">
                        <div className="applicantDivs" style={{ width: '40%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Company</div>
                            <div>{applicantData?.company}</div>
                        </div>
                        <div className="applicantDivs" style={{ width: '60%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Designation</div>
                            <div>{applicantData?.designation}</div>
                        </div>
                        {/* <div className="applicantDivs" style={{ width: '60%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Designation</div>
                            <div>{applicantData?.designation}</div>
                        </div> */}
                    </FlexComponent>
                    
                

                </FlexComponent >

                {/* <FlexComponent className="applicantContainer" style={{ width: '35%' }} flexDirection="column" gap="1rem" alignItems="start">
                    <Button onClick={() => window.open(`https://phone.bcrumbs.com/v2/location/${applicantData?.location_id}/contacts/detail/${applicantData?.ghl_contact_id}`, '_blank')} style={{ fontSize: '14px', fontWeight:"bold",  background: 'transparent', border: '1px solid grey', width: '180px' }}><i className="pi pi-external-link"></i> <span style={{ marginLeft: '0.5rem' }}>View Contact</span></Button>
                </FlexComponent> */}

            </FlexComponent>
            <div style={{ marginTop: '2rem' }} >
                
                <Divider />
                {/* <Description description={applicantData?.description} usersList={usersList} formId={applicantData?.user_id} toast={toast} />
                <Divider /> */}
                {applicantData?.user_id && <UserActivity usersList={usersList} handleClose={handleClose} toast={toast} userId={userId} formId={applicantData?.user_id} comments={applicantData?.comments} />}
            </div>
        </div>
    )
}

export default UserPopup