import React, { useRef, useState } from "react";
import { Mention } from 'primereact/mention';
import './apps.css';
import { Button } from "primereact/button";
import FlexComponent from "../../FlexComponent";
import { Avatar } from "primereact/avatar";
import { createComment, createUserComment, deleteComment, deleteUserComment, updateComment, updateUserComment } from "../../../utils/urls";
import axios from '../../../utils/axios';
import { useSelector } from "react-redux";
import { Divider } from "primereact/divider";
import fbImage from "../../../Images/fbImage.png"
import instaImage from "../../../Images/instaImage.png"
import adminLogo from '../../../Images/admin_img.jpg'

export default function UserActivity({ handleClose, toast, formId, usersList, section = "popup", comments }) {

    const [value, setValue] = useState('');
    const [editValue, setEditValue] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [editUsers, setEditUsers] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [commentsData, setCommentsData] = useState(comments);
    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const user = useSelector((state) => state.user);
    const [commentLoad, setCommentLoad] = useState(false)
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioError, setAudioError] = useState(false)
    // const isSuperuser = useSelector((state) => state.user.isSuperuser);

    const [hideCheckListItems, setHideCheckListItems] = useState(true)

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    const onSearch = (event) => {
        setTimeout(() => {
            const query = event.query.toLowerCase();
            let filteredSuggestions = [];

            if (!query.trim().length) {
                filteredSuggestions = [...usersList];
            } else {
                filteredSuggestions = usersList.filter((user) =>
                    user.label.toLowerCase().startsWith(query)
                );
            }

            setSuggestions(filteredSuggestions);
        }, 250);
    };

    const handleSaveNote = () => {
        const data = {
            comment: value,
            mention: selectedUsers.map(item => ({ id: item.id })),
            type: "comment"
        }
        console.log(data)
        setCommentLoad(true)
        const Url = `${createUserComment}${formId}/${user.userId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    const newCommentObj = {
                        user_name: user.userName,
                        user_id: user.userId,
                        user_image: user.image,
                        comment_id: response.data.comment_id,
                        type: "comment",
                        comment: value,
                        mention: selectedUsers,
                        created_at: response.data.created_at
                    }
                    setCommentsData(prev => [newCommentObj, ...prev]);
                    // setBoardSections(prevSections => {
                    //     const updatedSections = { ...prevSections };
                    //     const section = updatedSections[id].map(item => {
                    //         if (item.user_id === formId) {
                    //             return {
                    //                 ...item,
                    //                 comments: [newCommentObj, ...item.comments]
                    //             };
                    //         }
                    //         return item;
                    //     });
                    //     updatedSections[id] = section;
                    //     return updatedSections;
                    // });
                    setCommentLoad(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        setValue('');
        setSelectedUsers([]);
    };

    const handleDelete = (commentId) => {
        const updatedData = commentsData?.filter(comment => comment.comment_id !== commentId);
        setCommentsData(updatedData);
        const Url = `${deleteUserComment}${commentId}`
        axios.post(Url, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setBoardSections(prevSections => {
                    //     const updatedSections = { ...prevSections };
                    //     const section = updatedSections[id].map(item => {
                    //         if (item.user_id === formId) {
                    //             return {
                    //                 ...item,
                    //                 comments: item.comments.filter(comment => comment.comment_id !== commentId)
                    //             };
                    //         }
                    //         return item;
                    //     });
                    //     updatedSections[id] = section;
                    //     return updatedSections;
                    // });
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })

    };

    const handleEdit = (index) => {
        setIsEdit(true);
        setEditIndex(index);
        setEditValue(commentsData[index].comment);
        setEditUsers(extractUsers(commentsData[index].comment));
    };

    const handleCancel = () => {
        setIsEdit(false)
    }

    const handleSaveEdit = (commentId) => {
        const data = {
            comment: editValue,
            mention: editUsers.map(item => ({ id: item.id })),
        }
        const updatedData = [...commentsData];
        updatedData[editIndex].comment = editValue;
        setCommentsData(updatedData);
        setIsEdit(false);
        setEditIndex(null);
        setEditValue('');
        setEditUsers([]);

        const Url = `${updateUserComment}${commentId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setBoardSections(prevSections => {
                    //     const updatedSections = { ...prevSections };
                    //     const section = updatedSections[id].map(item => {
                    //         if (item.user_id === formId) {
                    //             return {
                    //                 ...item,
                    //                 comments: item.comments.map(comment =>
                    //                     comment.comment_id === commentId ? { ...comment, comment: editValue } : comment
                    //                 )
                    //             };
                    //         }
                    //         return item;
                    //     });
                    //     updatedSections[id] = section;
                    //     return updatedSections;
                    // });
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })

    };

    const extractUsers = (text) => {
        // Update the regex pattern to match @mentions with periods, underscores, and hyphens
        const mentionPattern = /@([\w.-]+)/g;
        const mentions = [...text.matchAll(mentionPattern)].map(match => match[1]);
        return mentions.map(mention => {
            const user = usersList.find(user => user.label.replace(/\s+/g, '').toLowerCase() === mention.toLowerCase());
            return user ? { id: user.value, name: user.label } : null;
        }).filter(user => user !== null);
    };

// console.log(usersList)
    const highlightMentionsAndUrls = (text) => {
        // Regular expression to match mentions
        const mentionRegex = /@[\w.-]+/g;
        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        // Split text into parts that contain mentions, URLs, and line breaks
        const parts = text.split(/(@[\w.-]+|https?:\/\/[^\s]+|\n)/g);

        return parts.map((part, index) => {
            if (mentionRegex.test(part)) {
                // Highlight mentions
                return (
                    <span key={`mention-${index}`} style={{ color: '#2FC623' }} className="mention">
                        {part}
                    </span>
                );
            } else if (urlRegex.test(part)) {
                // Highlight URLs
                return (
                    <a key={`url-${index}`} href={part} style={{ color: '#00FF2F' }} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            } else if (part === '\n') {
                // Handle line breaks
                return <br key={`br-${index}`} />;
            } else {
                // Regular text
                return part;
            }
        });
    };


    const onChange = (e) => {
        const inputValue = e.target.value;
        setValue(inputValue);
        setSelectedUsers(extractUsers(inputValue));
    };

    const onEditChange = (e) => {
        const inputValue = e.target.value;
        setEditValue(inputValue);
        setEditUsers(extractUsers(inputValue));
    };

    const itemTemplate = (suggestion) => {
        return (
            <div className="flex align-items-center">
                <span className="flex flex-column ml-2">
                    {suggestion.label}
                </span>
            </div>
        );
    };

    function highlightText(text) {
        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const parts = text?.split(urlRegex);

        return (
            <div>
                {parts?.map((part, index) => {
                    if (urlRegex.test(part)) {
                        return (
                            <a key={index} href={part} style={{ color: '#00FF2F' }} target="_blank" rel="noopener noreferrer">
                                {part}
                            </a>
                        );
                    } else {
                        return part;
                    }
                })}
            </div>
        );
    };

    // console.log('selectedUsers', selectedUsers)
    // console.log('editUsers', editUsers)
    // console.log("userData", user)

    return (
        <div className="activity" style={{ overflow: "hidden" }} >
            {/* <SocketSample/> */}
            <FlexComponent>
                {section === "popup" && <p style={{ fontSize: '14px', color: '#B1B1B1' }}>Activity</p>}
                {/* <Button style={{ boxShadow: "none", padding: "0.4rem 1rem", border: "1px solid grey", marginLeft:".5rem" }} onClick={() => {
                    setHideCheckListItems(!hideCheckListItems)
                }}
                >{hideCheckListItems ? `Show Check List Items` : 'Hide Check List Items'}</Button> */}
            </FlexComponent>
            <FlexComponent justifyContent="start" alignItems="start" gap="1rem" style={{ width: section === "popup" ? "100%" : "93%", marginTop: '0.7rem', marginLeft: section !== "popup" && "1rem" }} className="commentSectionContainer">
                <Avatar style={{ width: '40px', height: '40px', color: 'black' }} image={user.userName === "admin" ? adminLogo : user?.image} shape='circle' label={getInitials(user.userName)} />
                <Mention
                    value={value}
                    onChange={onChange}
                    suggestions={suggestions}
                    onSearch={onSearch}
                    field="label"
                    placeholder="Write a comment..."
                    // rows={1}
                    // cols={50}
                    itemTemplate={itemTemplate}
                    className={section === "popup" ? "mention-input" : "profile-input"}
                />
            </FlexComponent>
            <div style={{ marginLeft: section === "popup" ? "8%" : "10%", marginTop: "10px" }} >
                {value?.trim() && <Button onClick={handleSaveNote}>Save</Button>}
            </div>

            <FlexComponent className='appSection applicantContainer' justifyContent="start" flexDirection="column" gap={section === "popup" ? "1rem" : ""} style={{
                borderRadius: "15px",
                // overflowY: commentsData?.length > 0 ? 'scroll' : 'auto', height: commentsData?.length > 0 ? '150px' : '50px', 
                margin: '10px 0', padding: "1rem"
            }}>
                {commentLoad &&
                    <div style={{ width: "100%" }}>
                        <FlexComponent alignItems="start">
                            <div  >
                                <Avatar style={{ width: '35px', height: '35px', color: 'black' }} shape='circle' />
                            </div>
                            <div style={{ width: section === "popup" ? "93%" : "100%", height: "50px", borderRadius: "10px", background: section === "profileView" && "grey" }} className="loadingAppItem">

                            </div>
                        </FlexComponent>
                    </div>
                }
                {commentsData?.map((comment, index) => (
                    <div key={index} style={{ width: "100%", display : hideCheckListItems && comment.type === "checklist" ? "none" : "" }} >
                        {<FlexComponent gap={section === "popup" ? "" : "1rem"} alignItems="start" className="commentSectionContainer" style={{ height: index + 1 === commentsData.length && section === "popup" && '35vh' }}>
                            <div >
                                {comment.type == "Facebook" ? <Avatar image={fbImage} style={{ width: '35px', height: '35px', color: 'black', background: "white" }} shape='circle' />
                                    : comment.type == "Instagram" ? <Avatar image={instaImage} style={{ width: '35px', height: '35px', color: 'black', background: "white" }} shape='circle' /> :
                                        <Avatar image={comment.user_name === "admin" ? adminLogo : comment.user_image} style={{ width: '35px', height: '35px', color: 'black' }} shape='circle' label={getInitials(comment?.user_name)} />
                                }
                            </div>
                            <div style={{ width: section === "popup" ? "93%" : "100%" }} className="commentSectionDiv">
                                <FlexComponent justifyContent="start" gap="0.5rem" style={{ marginTop: section === "popup" ? "" : ".4rem" }} >
                                    {comment.type !== "Facebook" && comment.type !== "Instagram" && <p style={{ fontSize: '14px', color: section === "popup" ? 'white' : "white", fontWeight: "bold" }}>{comment?.user_name}</p>}
                                    <p style={{ fontSize: '12px', color: '#B1B1B1' }}>{comment?.created_at}</p>
                                </FlexComponent>
                                {!isEdit || editIndex !== index ? (
                                    <>
                                        {comment.type !== "call" && <FlexComponent gap="1rem" style={{ margin: "0.5rem 0", border: (comment.type === "comment" && section === "popup") ? '2px solid grey' : comment.type === "comment" && '1px solid #dee2e6', padding: comment.type === "comment" || section === "popup" ? "0.5rem" : "0.5rem 0", background: section === "popup" ? '#0808086b' : "transparent", borderRadius: ".5rem", }}>
                                            <p style={{ wordBreak: "break-word" }} >{highlightMentionsAndUrls(comment.comment)}</p>
                                        </FlexComponent>}
                                    </>
                                ) : (
                                    <Mention
                                        value={editValue}
                                        onChange={onEditChange}
                                        suggestions={suggestions}
                                        onSearch={onSearch}
                                        field="label"
                                        placeholder="Edit comment..."
                                        // rows={1}
                                        cols={50}
                                        itemTemplate={itemTemplate}
                                        className="edit-mention-input"
                                        style={{ width: "100%", margin: "0.5rem 0" }}
                                    />
                                )}
                                {comment.user_id === user.userId && comment.type === "comment" && <FlexComponent justifyContent="start" gap="0.3rem">
                                    {!isEdit || editIndex !== index ? (
                                        <>
                                            <a style={{ color: '#B1B1B1', fontSize: "14px", cursor: "pointer" }} onClick={() => handleDelete(comment.comment_id)}>
                                                delete
                                            </a>
                                            <a style={{ color: '#B1B1B1', fontSize: "14px", cursor: "pointer" }} onClick={() => handleEdit(index)}>
                                                edit
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <Button style={{ color: 'white', fontSize: "14px", cursor: "pointer", background: "#2FC635" }} onClick={() => {
                                                handleSaveEdit(comment.comment_id)
                                            }} disabled={!editValue?.trim()} >
                                                save
                                            </Button>
                                            <Button style={{ color: 'black', fontSize: "14px", cursor: "pointer", background: "white" }} onClick={handleCancel}>
                                                Discard Changes
                                            </Button>
                                        </>
                                    )}
                                </FlexComponent>}

                                {comment.type === "call" && <FlexComponent justifyContent="start" gap="0.3rem" style={{ marginTop: ".5rem", background: section === "popup" ? '#0808086b' : "transparent", borderRadius: ".5rem", padding: section === "popup" ? "1rem" : "" }} >
                                    <audio style={{ width: "100%" }} controls ref={audioRef} onEnded={() => setIsPlaying(false)} onError={() => setAudioError(true)}>
                                        <source src={comment?.comment} type="audio/mpeg" />
                                    </audio>
                                </FlexComponent>}
                            </div>
                        </FlexComponent>}
                        {section === "profileView" && <Divider />}
                    </div>
                ))}
            </FlexComponent>
        </div>
    );
}
