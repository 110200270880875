import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { purchaseFormData } from '../../utils/urls';   
import axios from '../../utils/axios';
import { fallbackUserList, fallbackUserCreate, fallbackUserDelete } from '../../utils/urls'; 
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

export default function FallbackUserTable() {
    const {campaignId} = useParams()
    const toast = useRef(null);
    const url = `${fallbackUserList}${campaignId}`
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const [visible, setVisible] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [locations, setLocations] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        location: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        user: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    const dt = useRef(null);

    useEffect(()=>{
        // setLoading(true);
        axios.get(url, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(response.data);
                    setLoading(false);
                    // initFilters();
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[url, isMounted])

    useEffect(()=>{
        // setLoading(true);
        axios.get(purchaseFormData, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setLocations(response.data.locations)
                    setAllUsers(response.data.users)
                    // initFilters();
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[purchaseFormData, isMounted])


    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const addFallbackUser = () =>{
        setVisible(true)
    }

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className="flex justify-content-between table-header-container">
                 <div className='sync-button-container'>
                    <Button className='sync-button' label="ADD" icon="pi pi-plus" onClick={addFallbackUser} />
                </div>
                <div className='header-container-with-csv-export'>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                    </span>
                    <div className="flex align-items-center justify-content-end gap-2">
                        <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                    </div>
                </div>
            </div>
        );
    };

    const handlefallbackUserSubmit = () => {
        
        const usersArray = selectedUser.map(item => item.id);
        const data = {
            'campaign_id' : campaignId,
            'location_id': selectedLocation.location_id,
            'users': usersArray
        }

        axios.post(fallbackUserCreate, data,{
            headers: {'Content-Type': 'application/json'},
          })
        .then((response)=>{
            if (response.status === 201){
                setSelectedLocation('')
                setSelectedUser('')
                setIsMounted(!isMounted);
                setVisible(false);
                toast.current.show({severity:'success', summary: 'Success', detail:'User added', life: 3000});
            }

            else if (response.status === 208){
                const response_message = response.data
                toast.current.show({severity:'error', summary: 'Error', detail: response_message, life: 3000}); 
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
        })         
    }

    const handleLocationChange = (e) =>{
     
        setSelectedLocation(e.value);
        setSelectedUser('')
        const filtered = filterUsersByLocation(allUsers, e.value);
        const formatted = formatFilteredUsers(filtered);
        setFilteredUsers(formatted)
          
    }

    const filterUsersByLocation = (users, selectedLocation) => {
        return users.filter(user => user.locationId.includes(selectedLocation.location_id));
    };

    const formatFilteredUsers = (filteredUsers) => {
    return filteredUsers.map((user, index) => ({
        id: user.id,
        name: user.name
    }));
    };

    const footerContent = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handlefallbackUserSubmit} autoFocus />
        </div>
    );

    const accept = (data) => {
        axios.post(fallbackUserDelete, data,{
            headers: {'Content-Type': 'application/json'},
          })
        .then((response)=>{
            if (response.status === 200){
                setIsMounted(!isMounted);
                setVisible(false);
                toast.current.show({severity:'success', summary: 'Success', detail:'User Deleted', life: 3000});
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
        })         
    }

    const reject = () => {
        console.log('closed')
    }

    const handleDeleteFallbackUser = (fallback_id, user_id, location_name) => {
        const data = {
            fallback_id,
            user_id,
            location_name
        }
        
        confirmDialog({
            message: 'Are you sure you want to delete this fallback user?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(data),
            reject
        });

    }

    const deleteBody = (rowData) => {
        const fallback_id = rowData.id
        const user_id = rowData.user_id
        const location_name =  rowData.location
        return <Button icon="pi pi-trash" severity="danger" aria-label="Delete" onClick={()=>handleDeleteFallbackUser(fallback_id, user_id, location_name)}/>;
    };

    const handleOnHide = () =>{
        setSelectedLocation('')
        setSelectedUser('')
        setVisible(false)
    }

    const header = renderHeader();
    return (
        <div className="card">
            <Toast ref={toast} />
            <ConfirmDialog />
            <DataTable ref={dt} value={customers} paginator header={header} rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" loading={loading} filters={filters} onFilter={(e) => setFilters(e.filters)} rowGroupMode="rowspan" groupRowsBy="location"
                    sortMode="single" sortField="location" sortOrder={1} tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" headerStyle={{ width: '5%' }} body={(data, options) => options.rowIndex + 1}></Column>
                <Column field="location" header="Team"style={{ Width: '40%' }}></Column>
                <Column field="user" header="User" style={{ Width: '40%' }} ></Column>
                <Column body={deleteBody} style={{ width: '10%' }}></Column>
            </DataTable>
            <Dialog header="Add Fallback User" visible={visible} style={{ width: '50vw' }} onHide={handleOnHide} footer={footerContent}>
                <div>
                    <div className='purchase-form-group'>

                        <div className="card flex justify-content-center form-field-container">
                            <Dropdown value={selectedLocation} onChange={handleLocationChange} options={locations} optionLabel="name" 
                                placeholder="Select a Location" filter className="w-full md:w-14rem purchase-dropdown" />
                        </div>

                        <div className="card flex justify-content-center form-field-container">
                            {/* <Dropdown value={selectedUser} onChange={(e) => setSelectedUser(e.value)} options={filteredUsers} optionLabel="name" 
                                placeholder="Select a User" filter className="w-full md:w-14rem purchase-dropdown" /> */}
                            <MultiSelect value={selectedUser} onChange={(e) => setSelectedUser(e.value)} options={filteredUsers} optionLabel="name" 
                                placeholder="Select a User" maxSelectedLabels={100} className="w-full md:w-20rem purchase-dropdown" />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
        