import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { BubblyLink } from "../library/src";
import './Nav.css'
import FlexComponent from '../components/FlexComponent';

export default function MobileNav({ isSuperuser, is_leader }) {
    const [visible, setVisible] = useState(false);
    const [formsExpanded, setFormsExpanded] = useState(false); // Manage Forms collapse state

    const MyBubblyLink = ({ to = "", text = "" }) => (
        <BubblyLink to={to} colorStart="#da474e" colorEnd="#ffffff">
            {text}
        </BubblyLink>
    );

    return (
        <div className="mobile-nav">
            <Sidebar visible={visible} onHide={() => setVisible(false)} className="w-full md:w-20rem lg:w-30rem">
                {isSuperuser ?
                    <nav className="animate-in mobile-nav-link" style={{ animationDelay: "800ms" }}>
                        <MyBubblyLink to="/" text="Dashboard" />
                        <MyBubblyLink to="/callers-leaderboard" text="Caller Leaderboard" />
                        <MyBubblyLink to="/shoppers-leaderboard" text="Shopper Leaderboard" />
                        <MyBubblyLink to="/participant" text="Participants" />

                        <div className="forms-section" style={{ textAlign: "center" }}>
                            <Button style={{ background: "white", color: "#495057", border: "none", boxShadow: "none" }}
                                className="forms-toggle mobile-nav-link react-bubbly-transitions__bubbly-link"
                                onClick={() => setFormsExpanded(!formsExpanded)}
                            >
                                Forms <span style={{ fontSize: "1.5rem", marginTop: ".5rem" }} className={formsExpanded ? 'pi pi-angle-up' : 'pi pi-angle-down'} ></span>
                                {/* {formsExpanded ? "▲" : "▼"} */}
                            </Button>
                            {formsExpanded && (
                                <FlexComponent flexDirection="column" gap="1rem" className="forms-links" style={{marginTop: ".8rem"}}>
                                    <MyBubblyLink style={{fontSize: "15px"}} to="/new-app" text="New Mortgage App" />
                                    <MyBubblyLink to="/new-buyer-app" text="New Buyer App" />
                                    <MyBubblyLink to="/new-loan-set-up-form" text="New Loan SetUp Form" />
                                    <MyBubblyLink to="/new-app-short" text="Short Form App" />
                                </FlexComponent>
                            )}
                        </div>

                        <MyBubblyLink to="/app-table/table" text="App" />
                        <MyBubblyLink to="/team" text="Teams" />
                        <MyBubblyLink to="/campaign" text="Campaigns" />
                        <MyBubblyLink to="/purchase" text="Purchases" />
                        <MyBubblyLink to="/settings" text="Settings" />
                    </nav>
                    :
                    <nav className="animate-in mobile-nav-link" style={{ animationDelay: "800ms" }}>
                        <MyBubblyLink to="/" text="Dashboard" />
                        <MyBubblyLink to="/callers-leaderboard" text="Caller Leaderboard" />
                        <MyBubblyLink to="/shoppers-leaderboard" text="Shopper Leaderboard" />
                        {is_leader &&
                            <MyBubblyLink to="/participant" text="Participants" />
                        }
                        <MyBubblyLink to="/app-kanban" text="App" />
                        <div className="forms-section" style={{ textAlign: "center" }}>
                            <Button style={{ background: "white", color: "#495057", border: "none", boxShadow: "none" }}
                                className="forms-toggle mobile-nav-link react-bubbly-transitions__bubbly-link"
                                onClick={() => setFormsExpanded(!formsExpanded)}
                            >
                                Forms <span style={{ fontSize: "1.5rem", marginTop: ".5rem" }} className={formsExpanded ? 'pi pi-angle-up' : 'pi pi-angle-down'} ></span>
                                {/* {formsExpanded ? "▲" : "▼"} */}
                            </Button>
                            {formsExpanded && (
                                <FlexComponent flexDirection="column" gap="1rem" className="forms-links" style={{marginTop: ".8rem"}}>
                                    <MyBubblyLink style={{fontSize: "15px"}} to="/new-app" text="New Mortgage App" />
                                    <MyBubblyLink to="/new-buyer-app" text="New Buyer App" />
                                    <MyBubblyLink to="/new-loan-set-up-form" text="New Loan SetUp Form" />
                                    <MyBubblyLink to="/new-app-short" text="Short Form App" />
                                </FlexComponent>
                            )}
                        </div>
                        {/* <MyBubblyLink to="/team" text="Teams" /> */}
                        {/* <MyBubblyLink to="/campaign" text="Campaigns" /> */}
                        {/* <MyBubblyLink to="/purchase" text="Purchases" /> */}
                        {/* <MyBubblyLink to="/settings" text="Settings" /> */}
                    </nav>
                }
            </Sidebar>
            <Button icon="pi pi-bars" style={{ background: "transparent" }} onClick={() => setVisible(true)} />
        </div>
    )
}
