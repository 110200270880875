import { useEffect, useContext, useState } from 'react'
import ReportingDashboard from "../../components/reportingDashbaord/ReportingDashbaord";
import { Nav } from "../../shared/Nav";
import Title from "../../shared/Title";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { verifyToken } from '../../utils/urls';
import jwt_decode from "jwt-decode";
import axios from '../../utils/axios'
import { RefreshToken } from '../../utils/refreshToken';
import { useDispatch } from 'react-redux';
import { setUser } from '../../utils/userSlice';
import { useSelector } from 'react-redux'
import { SampleNav } from '../../shared/SampleNav';
import adminLogo from '../../Images/admin_img.jpg'

const Wrapper = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const isSuperuser = useSelector((state) => state.user.isSuperuser);
  const image = useSelector((state) => state.user.image);
  const userLable = useSelector((state) => state.user.userLable);
  const userName = useSelector((state) => state.user.userName);
  const is_leader = useSelector((state) => state.user.is_leader);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {

    const authTokens = JSON.parse(localStorage.getItem('authTokens'))
    const refresh = authTokens?.refresh
    if (refresh) {
      const body = JSON.stringify({
        token: refresh,
      });
      axios
        .post(verifyToken, body, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status === 200) {
            const access = authTokens?.access
            const decodedToken = jwt_decode(access)
            dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser, userLable: decodedToken.userLable, image: decodedToken.image, userName: decodedToken.userName, ghlUserId: decodedToken.ghlUserId, is_leader: decodedToken.is_leader }));
            localStorage.setItem("prevUrl", window.location.pathname);
            // console.log(decodedToken.is_superuser)
            // if (decodedToken.is_superuser === true){
            //   localStorage.setItem("prevUrl", window.location.pathname);
            // }
            // else{
            //   localStorage.removeItem('authTokens');
            //   localStorage.removeItem('prevUrl');
            //   navigate('/login')
            // }

          }
        })
        .catch((err) => {
          localStorage.removeItem('authTokens');
          localStorage.removeItem('prevUrl');
          dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
          // dispatch(change({ id: null ,username: null, image: null }));
          // setIsLoggedIn(false)
          navigate('/login')
        });
    } else {
      localStorage.removeItem('authTokens');
      localStorage.removeItem('prevUrl');
      dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
      // dispatch(change({ id: null ,username: null, image: null }));
      // setIsLoggedIn(false)
      navigate('/login')
    }
  }, [])


  useEffect(() => {
    const intervalId = setInterval(async () => {
      await RefreshToken();
    }, 3600000);

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('authTokens');
        localStorage.removeItem('prevUrl');
        // dispatch(change({ username: null, image: null }));
        navigate('/login')
      }
    })
  }

  return (
    <>
      <header className={scrolling ? 'sticky' : ''}>
        {/* <Title>Contact</Title> */}
        <Nav 
          handleLogout={handleLogout} 
          isSuperuser={isSuperuser} 
          userLable={userLable} 
          image={image} 
          userName={userName} 
          is_leader={is_leader} 
        />
        {/* <SampleNav handleLogout={handleLogout} isSuperuser={isSuperuser} userLable={userLable} image={image} userName={userName} is_leader={is_leader}/> */}
      </header>
      <div 
        className='table-container' 
        style={{
          paddingBottom: '2rem',
          backgroundImage: `url("${isSuperuser ? adminLogo : image}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // height: '100vh',
          position: 'relative' // Needed for overlay positioning
        }}
      >
        <div
        className='table-inner-div'
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0 // Below content
          }}
        />
          <ReportingDashboard />
      </div>
    </>
  );
}
export default Wrapper;