import { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { getShoppersLeaderBoard, updateShopperGoal } from '../../utils/urls';
import axios from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import FlexComponent from '../FlexComponent';
import { Avatar } from 'primereact/avatar';
import './leaderBoard.css'
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

export default function ShoppersTable({ isSuperuser, ghlUserId }) {
    const navigate = useNavigate();
    // const ghlUserId = "gOxDKuJ9hLEPxEXVKMa2"
    const [appLeads, setAppLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false);
    const [fieldsData, setFieldsData] = useState({})
    const dt = useRef(null);

    const generateDateRange = () => {
        const today = new Date();
        const last7thDay = new Date();
        last7thDay.setDate(today.getDate() - 7);
        // console.log(last7thDay)
        return [last7thDay, today];
    };

    const [dates, setDates] = useState(() => generateDateRange());

    const getShoppersFn = (isLoading = true) => {
        if (dates[0] && dates[1]) {
            setLoading(true)
            const formatDate = (date) => date?.toLocaleDateString('en-CA');

            const startDate = formatDate(dates[0]) || "";
            const endDate = formatDate(dates[1]) || "";

            axios.get(`${getShoppersLeaderBoard}?type=shoppers`, {
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => {
                    if (response.status === 200) {
                        // console.log(response.data);
                        const updatedSortedData = [...response.data].sort((a, b) => {
                            return b["count"] - a["count"];
                        });
                        setAppLeads(updatedSortedData);
                        updatedSortedData?.map((field) => {
                            setFieldsData((prev) => ({
                                ...prev,
                                [field.name]: field.goal
                            }))
                        })
                        if (isLoading) {
                            setLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    setAppLeads([]);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        getShoppersFn()
    }, [isMounted, dates]);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        company: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    // console.log(numberOneId)

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <FlexComponent key={rowIndex} justifyContent="start" gap="1rem" style={{ paddingLeft: "2rem" }} >
            <div style={{ width: "30px" }}><span>{rowIndex}</span> </div>
            <Avatar size="medium" image={rowData?.image} shape='circle'
                label={<span style={{ padding: '5px' }}>{getInitials(rowData?.name)}</span>}
                style={{ background: 'white', border: '2px solid #8C8C8C', width: '40px', height: '40px' }} />
            <span>{rowData?.name}</span>
        </FlexComponent>;
    };

    const appsBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        // console.log("rowData", rowData)
        return <FlexComponent key={rowIndex} justifyContent="start" gap=".2rem" >
            {rowData?.apps?.map((app, index) => (
                <Avatar key={index} size="medium" image={app?.image} shape='circle'
                    onClick={() => window.open(`/app/${app?.contact_id}/${app?.app_id}`, '_blank')}
                    label={<span style={{ padding: '5px' }}>{app?.headshort}</span>}
                    style={{ background: 'white', border: '2px solid #8C8C8C', width: '40px', height: '40px', cursor: "pointer" }} />
            ))}
        </FlexComponent>;
    };

    const updateGoalField = (goalValue, userId) => {
        // setLoading(true)
        const data = {
            user_id: userId,
            goal: parseInt(goalValue)
        }
        console.log(data)
        axios.post(updateShopperGoal, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    //   getApplicantData()
                    getShoppersFn(false)
                    //   setLoading(false)
                }
            })
            .catch((error) => {
                // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                // setLoading(false)
            })
    }

    const goalsBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <FlexComponent key={rowIndex} justifyContent="start" gap=".2rem" >
            {isSuperuser || ghlUserId === rowData.user_id ? <InputText min={0} style={{ width: '100px', textAlign: "center" }} id="goal" value={fieldsData[rowData.name]} type='number'
                onChange={(e) => {
                    let value = Math.max(0, parseInt(e.target.value));
                    console.log(value);

                    setFieldsData((prev) => ({
                        ...prev,
                        [rowData.name]: value || 0
                    }));
                }}
                onBlur={(e) => {
                    updateGoalField(e.target.value, rowData.user_id)
                }}
            /> : <div style={{ textAlign: "center", width: "85px" }} >{rowData.goal}</div>}
        </FlexComponent>;
    };

    const countBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div key={rowIndex}  >
            <div style={{ width: "85px", color: (rowData.count >= rowData.goal) ? "#2EBE27" : "" }} >{rowData.count}</div>
        </div>
    };

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
    };
    // console.log(filters)

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';
        return (
            <div className='header-container-with-csv-export'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
                <div className="flex align-items-center justify-content-end gap-2">
                    <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                </div>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <div >
            <FlexComponent className='applicantDivs' justifyContent="end" gap="1rem">
                <div className='applicantDivs' style={{ color: "white", zIndex: "1", fontSize: "22px", width: "60%" }} >
                    Shopper Leaderboard
                </div>
                {/* <div >
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" />
                </div> */}
            </FlexComponent>
            <div className="card p-fluid" style={{ marginTop: ".2rem" }} >
                <DataTable
                    ref={dt}
                    value={appLeads}
                    size='small'
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 30, 50, 100]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    scrollable
                    scrollHeight="65vh"
                    header={header}
                    filters={filters}
                    loading={loading}
                    onFilter={(e) => setFilters(e.filters)}
                    dataKey="user_id"
                    stateStorage="session"
                    stateKey="shopper-leader-board-table"
                    tableStyle={{ minWidth: '50rem' }}
                >
                    <Column header="" field="name" className="padding-0" body={srNoBodyTemplate} />
                    {/* <Column field="name" header="Name" style={{ minWidth: '13rem' }} /> */}
                    <Column field="company" header="Company" style={{ width: isSuperuser ? '15rem' : '20rem', fontWeight: "bold", fontSize: "1.1rem" }} />
                    <Column field="apps" header="Apps" body={appsBodyTemplate} />
                    <Column field="company" header="Company" style={{ minWidth: isSuperuser ? '15rem' : '20rem', fontWeight: "bold", fontSize: "1.1rem" }} />
                    <Column field="count" header="Count" body={countBodyTemplate} style={{ minWidth: '15rem', fontWeight: "bold", fontSize: "1.1rem" }} sortable />
                    <Column field="goal" header="Goal" body={goalsBodyTemplate} sortable />
                </DataTable>
            </div>
        </div>
    );
}
