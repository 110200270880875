import { useEffect, useContext, useState, useRef } from 'react'
import { Nav } from "../../shared/Nav";
import { useNavigate } from 'react-router-dom';
import { appKanbanUserList, appSubmission, getCompanies, getDesignations, getPipelines, getUserOpportunitiesList, getUserPipelines, registeredUserList, updateUserStage, verifyToken } from '../../utils/urls';
import jwt_decode from "jwt-decode";
import axios from '../../utils/axios'
import { RefreshToken } from '../../utils/refreshToken';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
// import CampaignTable from '../../components/campaigns/CampaignTable';
import { useDispatch } from 'react-redux';
import { setUser } from '../../utils/userSlice';
import { useSelector } from 'react-redux'
import GhlUserTable from '../../components/Leaders/GhlUserTable';
import OnboardedUsersTable from '../../components/Leaders/OnboardedUsersTable';
import { Button } from 'primereact/button';
import useDebounce from '../../components/apps/appsKanban/hooks/useDebounce';
import FlexComponent from '../../components/FlexComponent';
import { CancelToken } from 'axios'
import UserSectionList from '../../components/apps/usersKanban/UserSectionList';
import { InputText } from 'primereact/inputtext';
import adminLogo from '../../Images/admin_img.jpg'

const OnboardedUsers = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    // const { isLoggedIn, setIsLoggedIn } = useContext(UserIsLoggedIn);
    const { locationId } = useParams();
    // const {userId} = useParams();
    const isSuperuser = useSelector((state) => state.user.isSuperuser);
    const image = useSelector((state) => state.user.image);
    const userLable = useSelector((state) => state.user.userLable);
    const userId = useSelector((state) => state.user.userId);
    const userName = useSelector((state) => state.user.userName);
    const is_leader = useSelector((state) => state.user.is_leader);
    const [scrolling, setScrolling] = useState(false);
    const [designationList, setDesignationList] = useState([])
    const [companiesList, setCompaniesList] = useState([])

    const options = ['Board', 'Table'];
    // const [value, setValue] = useState(options[0]);
    const [page, setPage] = useState(options[0])

    const [loading, setLoading] = useState(false)
    const cancelTokenRef = useRef(null);
    const toast = useRef(null);
    const [isMounted, setIsMounted] = useState(false)

    const [pipelineId, setPipelineId] = useState(1)
    const [boardSections, setBoardSections] = useState({
        "⏲️ Waiting/Follow Up": [],
        "🤳 Self-Serve Quick App": [],
        "📃✨ New Application": [],
        "🤖 Auto Doc Request": [],
        "🔥 Prequalified Awaiting Docs": [],
        "📄 Docs in - Active Client": [],
        "✅ Preapproved": [],
        "🛒Shopping": [],
        "📈 Refi": [],
        "✅ Active Loan": []
    });
    const [pipeline, setPipeline] = useState('')
    const [stageIds, setStageIds] = useState({})
    const [stageAppCounts, setStageAppCounts] = useState({})
    const [usersList, setUsersList] = useState([])
    const [registeredUsersList, setRegisteredUsersList] = useState([])

    // const [incomeRangeValue, setIncomeRangeValue] = useState([0, 1000000])
    // const { searchQuery, setSearchQuery } = useDebounce(handleSearch, setLoading);
    const [searchQuery, setSearchQuery] = useState('');
    const debouncedSearchQuery = useDebounce(searchQuery, 300);

    // const options = ['Board', 'Table', 'Archived'];
    // const [value, setValue] = useState(options[0]);
    // const [page, setPage] = useState(table ? options[1] : options[0])
    // const op = useRef(null);
    const [total, setTotal] = useState(0);


    const handleScroll = () => {
        if (window.scrollY > 50) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const authTokens = JSON.parse(localStorage.getItem('authTokens'))
        const refresh = authTokens?.refresh
        if (refresh) {
            const body = JSON.stringify({
                token: refresh,
            });
            axios
                .post(verifyToken, body, {
                    headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                    if (response.status === 200) {
                        const access = authTokens?.access
                        const decodedToken = jwt_decode(access)
                        const superUser = decodedToken.is_superuser
                        if (superUser || decodedToken.user_id === 35) {
                            dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser, userLable: decodedToken.userLable, image: decodedToken.image, userName: decodedToken.userName, ghlUserId: decodedToken.ghlUserId, is_leader: decodedToken.is_leader }));
                            localStorage.setItem("prevUrl", window.location.pathname);
                        }
                        else {
                            navigate('/')
                        }
                        // console.log(decodedToken.is_superuser)
                        // if (decodedToken.is_superuser === true){
                        //   localStorage.setItem("prevUrl", window.location.pathname);
                        // }
                        // else{
                        //   localStorage.removeItem('authTokens');
                        //   localStorage.removeItem('prevUrl');
                        //   navigate('/login')
                        // }

                    }
                })
                .catch((err) => {
                    localStorage.removeItem('authTokens');
                    localStorage.removeItem('prevUrl');
                    dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
                    // dispatch(change({ id: null ,username: null, image: null }));
                    // setIsLoggedIn(false)
                    navigate('/login')
                });
        } else {
            localStorage.removeItem('authTokens');
            localStorage.removeItem('prevUrl');
            dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
            // dispatch(change({ id: null ,username: null, image: null }));
            // setIsLoggedIn(false)
            navigate('/login')
        }
    }, [])


    useEffect(() => {
        const intervalId = setInterval(async () => {
            await RefreshToken();
        }, 3600000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure to log out?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('authTokens');
                localStorage.removeItem('prevUrl');
                // dispatch(change({ username: null, image: null }));
                navigate('/login')
            }
        })
    }
    const getAllDesignations = () => {
        const url = `${getDesignations}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setDesignationList(response.data)

                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })
    }

    const getAllCompanies = () => {
        const url = `${getCompanies}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setCompaniesList(response.data)
                    // setLoading(false)
                }
            })
            .catch((error) => {
                // setLoading(false)
            })
    }

    useEffect(() => {
        getAllDesignations()
        getAllCompanies()
    }, [userId])

    const handleStageUpdate = (user_id, stage, prevStage) => {
        console.log(stage, prevStage)
        const data = {
            // stage: stage,
            userId: userId,
            stage_id: stageIds[stage],
            // email_list: emails
        }
        const stageUrl = `${updateUserStage}${user_id}`
        axios.post(stageUrl, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                    // handleSearch()
                    setStageAppCounts(prevCounts => ({
                        ...prevCounts,
                        [stage]: prevCounts[stage] + 1,
                        [prevStage]: prevCounts[prevStage] - 1
                    }))
                    const url = `${appSubmission}${user_id}?pipelineId=${pipelineId}`
                    axios.get(url, {
                        headers: { 'Content-Type': 'application/json' }
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                // console.log(response.data)
                                const data = response.data
                                setBoardSections(prevSections => {
                                    const updatedSections = { ...prevSections };
                                    for (const sectionId in updatedSections) {
                                        updatedSections[sectionId] = updatedSections[sectionId].map(item => {
                                            if (item.user_id === user_id) {
                                                return {
                                                    ...item,
                                                    due_date: data.due_date
                                                };
                                            }
                                            return item;
                                        });
                                    }
                                    return updatedSections;
                                });
                            }
                        })

                }
                else if (response.status === 208) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                    handleSearch()
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleSearch()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleSearch()
            })
    }

    const getAllPipelines = () => {
        if (pipelineId) {
            const url = `${getUserPipelines}?userId=${userId}`
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        const item = response?.data?.find(item => item.id == pipelineId);
                        console.log("item", item)
                        setPipeline(item)
                    }
                })
                .catch((error) => {
                    // setAppLeads(null)
                })
        }
    }


    const handleSearch = () => {
        if (userId && pipelineId) {
            setLoading(true)
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Operation canceled due to new request.');
            }
            cancelTokenRef.current = CancelToken.source();

            // const users = selectedUsers?.join(',')
            getAllPipelines()
            const url = `${getUserOpportunitiesList}?userId=${userId}&pipelineId=${pipelineId}&search=${searchQuery}&sort=DESC`

            axios.get(url, {
                headers: { 'Content-Type': 'application/json' },
                cancelToken: cancelTokenRef.current.token
            })
                .then((response) => {
                    if (response.status === 200) {
                        setLoading(true)
                        // console.log(response.data)
                        const stagesData = response.data.stages
                        const stagesCount = response.data.counts
                        const stageIdsData = response.data.stage_ids
                        const stageWatchersData = response.data.watchers
                        const updatedData = Object.keys(stagesData).reduce((acc, key) => ({
                            ...acc,
                            [key]: stagesData[key]?.map(item => ({ ...item, id: item.user_id }))
                        }), {});

                        setBoardSections(updatedData)
                        setStageAppCounts(stagesCount)
                        setTotal(Object.values(stagesCount).reduce((sum, count) => sum + count, 0))
                        setStageIds(stageIdsData)
                        // setStageWatchers(stageWatchersData)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    // setAppLeads(null)
                })
        }
    };



    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const loadUsersData = () => {
        const url = `${appKanbanUserList}?userId=${userId}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const options = response?.data?.map((user) => ({
                        label: user.name,
                        value: user.user_id,
                        image: user.image
                    })) || [];
                    setUsersList(options)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })

        const registeredUsersUrl = `${registeredUserList}`
        axios.get(registeredUsersUrl, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const options = response?.data?.map((user) => ({
                        label: user.name,
                        value: user.user_id,
                        image: user.image
                    })) || [];
                    setRegisteredUsersList(options)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })

    };

    const clearFilters = () => {
        setSearchQuery('');
        setLoading(true)
        handleSearch()
    }

    useEffect(() => {
        if (userId && pipelineId) {
            setLoading(true)
            handleSearch()
        }
    }, [userId, isMounted, pipelineId, debouncedSearchQuery])

    useEffect(() => {
        if (userId) {
            loadUsersData()
        }
    }, [userId])



    return (
        <>
            <header className={scrolling ? 'sticky' : ''}>
                <Nav handleLogout={handleLogout} isSuperuser={isSuperuser} userLable={userLable} image={image} userName={userName} is_leader={is_leader} />
            </header>
            
            <div 
            className='kanbanContainer' 
            style={{
            paddingBottom: '2rem',
            backgroundImage: `url("${isSuperuser ? adminLogo : image}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            position: 'relative' // Needed for overlay positioning
            }}
        >
            <div
            className='table-inner-div'
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0 // Below content
            }}
            />
                <FlexComponent justifyContent="end" alignItems="end" >
                {page === 'Board' && <Button className='piplelineTitle' style={{ cursor: "auto", color: "white", background: "transparent", boxShadow: "none", border: "none", width: "30%", fontSize:"20px", fontWeight:"bold" }} >{pipeline.name} {!loading && `- ${total}`}</Button>}

                    {page === 'Board' && <FlexComponent className='applicantDivs' style={{ width: "25%", marginRight: "2rem" }} >
                        <p style={{ color: "white", marginBottom: ".5rem" }} >Search</p>
                        <span className="p-input-icon-left " >
                            <i className="pi pi-search" />
                            <InputText type="search" placeholder='Enter a Keyword...' onChange={handleSearchChange} value={searchQuery} style={{ color: 'black' }} />
                        </span>
                    </FlexComponent>}
                    <div style={{ marginRight: "2rem" }} >
                        {<Button style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0", fontWeight: "600", background: page === 'Board' ? '#06243B' : 'white', color: page === 'Board' ? 'white' : 'black' }} onClick={() => {
                            setPage('Board')
                            handleSearch()
                        }}>Board</Button>}
                        <Button style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0", fontWeight: "600", background: page === 'Table' ? '#06243B' : 'white', color: page === 'Table' ? 'white' : 'black' }} onClick={() => {
                            setPage('Table')
                        }}>Table</Button>
                    </div>
                </FlexComponent>

                {page === 'Board' ? 
                    <FlexComponent flexDirection="column" justifyContent="end" alignItems="end" >
                        <FlexComponent flexDirection="column" className='applicant-cards' alignItems='start' gap="0.2rem" style={{ zIndex: '1' }}  >
                            <FlexComponent flexDirection='column' style={{ gap: '2rem', zIndex: '1', width: 'auto' }}>
                                <UserSectionList key={loading} toast={toast} userId={userId} pipeline={pipeline} usersList={registeredUsersList} setTotal={setTotal} stageAppCounts={stageAppCounts} stageIds={stageIds} setStageAppCounts={setStageAppCounts} boardSections={boardSections} setBoardSections={setBoardSections} handleStageUpdate={handleStageUpdate} searchQuery={searchQuery} isMounted={isMounted} setIsMounted={setIsMounted} loading={loading} />
                            </FlexComponent>
                        </FlexComponent>
                    </FlexComponent>
                
                    : <OnboardedUsersTable designationList={designationList} companiesList={companiesList} />
                }
                {/* <div className='table-container'>
                    <OnboardedUsersTable designationList={designationList} companiesList={companiesList} />
                </div> */}
            </div>
        </>
    );
}
export default OnboardedUsers;