import React, { useEffect, useRef, useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field, FieldArray } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import './form.css'
import { Dialog } from 'primereact/dialog'
import { Avatar } from 'primereact/avatar'
import { Country, State, City } from 'country-state-city';
import { Divider } from 'primereact/divider'


function AddressHistory({ values, errors, touched, handleBlur, handleChange }) {
    const [isEditOpen, setIsEditOpen] = useState(false); // State for popup visibility
    const [addressIndex, setAddressIndex] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [rentalIncome, setRentalIncome] = useState('');
    const [properyNote, setPropertyNote] = useState('');
    const [propertyIndex, setPropertyIndex] = useState(0)

    const handleAddClick = () => {
        setLoading(true)
        values.address_history.push({
            street_address: "",
            city: "",
            state: "",
            zip_code: "",
            home_status: "",
            status: "",
            notes_about_address: "",
            // do_you_own_any_other_property: "N",
            // rental_income: "",
            // notes_about_property_owned: "",
        });

        //   handleChange('work_history')(values.work_history); 
        setAddressIndex(values.address_history.length - 1)
        setIsEdit(false);
        setIsDelete(false)
        setIsEditOpen(true);
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    };

    const handlePropertyAddClick = () => {
        // setLoading(true)
        values.other_property_owned.push({
            rental_income: rentalIncome,
            notes_about_property_owned: properyNote,
        });

        setPropertyIndex(values.other_property_owned.length - 1)
        setRentalIncome('')
        setPropertyNote('')
    };


    const handleSave = () => {
        const addressEntry = values.address_history[addressIndex];
        const errors = {};
        if (!addressEntry.street_address?.trim()) errors.street_address = true;
        if (!addressEntry.city?.trim()) errors.city = true;
        if (!addressEntry.state?.trim()) errors.state = true;
        if (!addressEntry.zip_code?.trim()) errors.zip_code = true;
        if (!addressEntry.home_status) errors.home_status = true;
        if (!addressEntry.status && typeof addressEntry.status !== 'boolean') errors.status = true;

        setError(errors);

        if (Object.keys(errors).length === 0) {
            setIsEditOpen(false);
        }
    }

    const autoCompleteRef = useRef();
    const inputRef = useRef();

    // console.log(values)
    useEffect(() => {
        if (window.google && window.google.maps) {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
                types: ['geocode'],
                componentRestrictions: { country: "us" },
            });

            autoCompleteRef.current.addListener('place_changed', () => {
                const place = autoCompleteRef.current.getPlace();
                // console.log(place)
                handleChange(`address_history[${addressIndex}].street_address`)(place.formatted_address)
                const addressComponents = place.address_components;

                let address = {
                    city: '',
                    state: '',
                    postalCode: '',
                };

                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes("locality")) {
                        address.city = component.long_name;
                    }
                    if (types.includes("administrative_area_level_1")) {
                        address.state = component.long_name;
                    }
                    if (types.includes("postal_code")) {
                        address.postalCode = component.long_name;
                    }
                });
                handleChange(`address_history[${addressIndex}].state`)(address.state)
                handleChange(`address_history[${addressIndex}].city`)(address.city)
                handleChange(`address_history[${addressIndex}].zip_code`)(address.postalCode)

                // Manually trigger handleChange for each field
                // const cityEvent = {
                //     target: {
                //         name: 'city',
                //         value: address.city
                //     }
                // };
                // const stateEvent = {
                //     target: {
                //         name: 'state',
                //         value: address.state
                //     }
                // };
                // const postalCodeEvent = {
                //     target: {
                //         name: 'postalCode',
                //         value: address.postalCode
                //     }
                // };

                // handleChange(cityEvent);
                // handleChange(stateEvent);
                // handleChange(postalCodeEvent);
            });
        }
    }, [handleChange, loading]);

    // console.log(values)
    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='addressId'>
            <div style={{ background: '#08113A' }}>
                <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>Where do you live now?</p>
            </div>
            <div style={{ margin: "1rem 0.5rem" }}>
                <FlexComponent>
                    <label >Get a full 2-3 years of address history</label>
                    <svg onClick={handleAddClick} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" className="mercado-match" width="24" height="24" focusable="false">
                        <path d="M21 13h-8v8h-2v-8H3v-2h8V3h2v8h8z"></path>
                    </svg>
                </FlexComponent>
            </div>
            {!loading && values.address_history?.map((address, index) => (
                <FlexComponent key={index} style={{ background: 'white', margin: '1rem 0', padding: '1rem', borderRadius: '10px' }} gap='1rem'>
                    <FlexComponent alignItems='start' justifyContent='start' gap='1rem' style={{ textAlign: 'start' }}>
                        {/* <div>
                            <Avatar style={{width: '4.5rem', color:'white'}}  size="xlarge" />
                        </div> */}
                        <div style={{ width: '50%' }}>
                            <p>
                                <span style={{ color: '#8C8C8C' }}>Street Address : </span><span>{address?.street_address}, </span>
                            </p>
                            <p><span style={{ color: '#8C8C8C' }}>City : </span><span>{address?.city}</span></p>
                            <p ><span style={{ color: '#8C8C8C' }}>State : </span> <span>{address?.state}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Pin Code : </span><span >{address?.zip_code}</span></p>
                        </div>
                    </FlexComponent>
                    <Button type='button' onClick={() => {
                        setLoading(true)
                        setAddressIndex(index)
                        setIsEdit(true)
                        setIsDelete(true)
                        setIsEditOpen(true);
                        setTimeout(() => {
                            setLoading(false)
                        }, 1000)
                    }}>Edit</Button>
                </FlexComponent>
            ))}
            <div style={{ textAlign: "center", margin: '1rem' }}>
                <Button type='button' label="Add Address" style={{ borderRadius: '5px', fontSize: '16px', padding: '5px 20px', border: '1px solid #E0E4EC', background: 'white', color: '#8C8C8C' }} onClick={handleAddClick} />
            </div>
            <div style={{ textAlign: "center", margin: '1rem' }}>
                <ErrorMessage name="address_history" component="div" className="error" style={{ color: 'red' }} />
            </div>
            {/* ///////////////////// */}

            <Divider />
            <FlexComponent justifyContent='center'>
                <div style={{ width: '100%', }}>
                    <div className='inputContainerDiv' style={{ width: '40%', margin: '0.5rem' }}>
                        <label htmlFor="do_you_own_any_other_property" style={{ fontWeight: 'bold' }}>Do you own any other property?</label>
                        <br />
                        {["Y", "N"].map((option) => (
                            <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                <Field
                                    id={option}
                                    name="do_you_own_any_other_property"
                                    value={option}
                                    as={RadioButton}
                                    onChange={(event) => {
                                        // handleOtherPropertyChange(event.target.value)
                                        handleChange(`do_you_own_any_other_property`)(event.target.value)
                                        if (event.target.value === 'N') {
                                            console.log('noooo')
                                            // handleChange(`other_property_owned`)([])
                                            handleChange({ target: { name: `other_property_owned`, value: [] } });

                                        }
                                    }}
                                    checked={values.do_you_own_any_other_property === option} // Set checked state based on selectedOption
                                />
                                <label htmlFor={option} className="ml-2">{option}</label>
                            </FlexComponent>
                        ))}
                    </div>

                    {values.do_you_own_any_other_property === 'Y' && (
                        <>
                            <FlexComponent className='inputContainer' gap='1rem' alignItems='center' justifyContent='start'>
                                <div className='inputContainerDiv' style={{ width: '40%', margin: '0.5rem' }}>
                                    <label htmlFor="rental_income" style={{ fontWeight: 'bold' }}>Rental Income?</label>
                                    <div className="p-inputgroup flex-1 login-input-div">
                                        <span className="p-inputgroup-addon" style={{ background: 'white' }}>
                                            <i className="pi pi-dollar"></i>
                                        </span>
                                        <Field type="number" id="rental_income" value={rentalIncome} onChange={(event) => {
                                            setRentalIncome(event.target.value)
                                        }} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                                    </div>
                                </div>
                                <div className='inputContainerDiv' style={{ textAlign: 'start', margin: '0.5rem', width: '60%' }}>
                                    <label htmlFor="notes_about_property_owned" >
                                        <p style={{ fontWeight: 'bold' }}>Notes about Property Owned</p>
                                    </label>
                                    <Field rows={4} type="text" id="notes_about_property_owned" value={properyNote} onChange={(event) => {
                                        setPropertyNote(event.target.value)
                                    }} as={InputTextarea} style={{ width: '100%', height: '70px' }} />
                                </div>
                                <div className='inputContainerDiv' style={{ textAlign: 'center' }}>
                                    <Button disabled={rentalIncome ? false : true} type='button' onClick={handlePropertyAddClick}>Add</Button>
                                </div>
                            </FlexComponent>

                            <div className='inputContainerDiv' style={{ width: '70%', background: 'white', padding: values?.other_property_owned.length > 0 ? '0.5rem' : '', borderRadius: '10px', margin: '0.5rem' }}>
                                <FieldArray name="other_property_owned">
                                    {(arrayHelpers) => (
                                        <>
                                            {values?.other_property_owned?.map((property, index) => {
                                                return (
                                                    <div key={index}>
                                                        <FlexComponent gap='0.75rem' key={index} style={{ margin: '1rem' }}>
                                                            <FlexComponent flexDirection='column' alignItems='start'>
                                                                <p><span style={{ color: '#8C8C8C' }}>Rental Income: </span> <span>${property.rental_income}</span></p>
                                                                <p><span style={{ color: '#8C8C8C' }}>Notes about the Property: </span> <span>{property.notes_about_property_owned}</span></p>
                                                            </FlexComponent>
                                                            <Button type='button' style={{ margin: '0 1rem', padding: '.5rem' }} onClick={() => {
                                                                arrayHelpers.remove(index);
                                                            }}><i className="pi pi-trash" style={{ fontSize: '1rem' }}></i></Button>
                                                        </FlexComponent >
                                                        <Divider />
                                                    </div>
                                                );
                                            })}

                                        </>
                                    )}
                                </FieldArray>
                            </div>
                            <div style={{ padding: '1rem' }}>Be sure to gather any relevant details about the house, is there a mortgage on it? What is the address?</div>
                        </>)}
                </div>
            </FlexComponent>
            <Divider />

            {/* /////////////////////////// */}

            <FieldArray name="address_history"  >
                {(arrayHelpers) => (
                    <Dialog draggable={false} appendTo="self" className='historyForm formDiv' style={{ width: '65vw', marginTop: "5rem", height: "85vh" }} header="Add Address" visible={isEditOpen} onHide={() => {
                        if (!isEdit) {
                            arrayHelpers.remove(addressIndex);
                            setIsEdit(false)
                            setIsEditOpen(false);
                            setError('')
                        }
                        if (isEdit) {
                            handleSave()
                        }
                        setIsDelete(true)
                        // setIsEditOpen(false);
                    }}>
                        {/* -------------------- */}
                        <div style={{ textAlign: 'start', padding: '1.5rem' }}>
                            <FlexComponent className='inputContainer' alignItems="start" gap="1rem">
                                <FlexComponent style={{ width: '70%' }} className='inputContainer' flexDirection="column" gap='1rem' alignItems='start'>
                                    <div className='inputContainerDiv' style={{ width: '100%', margin: '0 0.5rem' }}>
                                        <label htmlFor="Home Address" style={{ fontWeight: 'bold', color: Boolean(error?.street_address || error?.state || error?.city || error?.zip_code) ? 'red' : '' }}>Home Address *</label>
                                        {/* <Field ref={inputRef}  type="text" id="street_address" name={`address_history[${addressIndex}].street_address`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} /> */}
                                        <InputText ref={inputRef} style={{ width: '100%' }} defaultValue={values?.address_history[addressIndex]?.street_address} id="street_address" name={`address_history[${addressIndex}].street_address`} onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange({
                                                    target: {
                                                        name : `address_history[${addressIndex}].street_address`,
                                                        value: e.target.value
                                                    }
                                                })
                                            }}
                                        />

                                        <label htmlFor="street_address" style={{ color: Boolean(error?.street_address) ? 'red' : '' }} >Street Address</label>
                                        {/* <ErrorMessage name="title" component="div" className="error" style={{color:'red'}} /> */}
                                    </div>
                                    <FlexComponent className='inputContainer' gap="1rem">
                                        <div className='inputContainerDiv' style={{ width: '50%', margin: '0 0.5rem' }}>
                                            <InputText style={{ width: '100%' }} defaultValue={values?.address_history[addressIndex]?.state} id="state" name={`address_history[${addressIndex}].state`} onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange({
                                                        target: {
                                                            name : `address_history[${addressIndex}].state`,
                                                            value: e.target.value
                                                        }
                                                    })
                                                }}
                                            />

                                            {/* <Field
                                                name={`address_history[${addressIndex}].state`}
                                            >{({ field }) => (
                                                <Dropdown
                                                    id={field.name}
                                                    value={values.address_history[addressIndex]?.state}
                                                    options={State.getStatesOfCountry('US')?.map((state) => ({
                                                        label: state.name,
                                                        value: state.isoCode,
                                                    })) || []}
                                                    style={{ width: '100%' }}
                                                    onChange={(event) => {
                                                        handleChange(`address_history[${addressIndex}].state`)(event.value)
                                                        handleChange(`address_history[${addressIndex}].city`)('')
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder="Select State"
                                                />
                                            )}</Field> */}
                                            <label htmlFor="state" style={{ color: Boolean(error?.state) ? 'red' : '' }}>State</label>
                                        </div>
                                        <div className='inputContainerDiv' style={{ width: '50%', margin: '0 0.5rem' }}>
                                            {/* <Field type="text" id="city" name={`address_history[${addressIndex}].city`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} /> */}
                                            <InputText style={{ width: '100%' }} defaultValue={values?.address_history[addressIndex]?.city} id="city" name={`address_history[${addressIndex}].city`} onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange({
                                                        target: {
                                                            name : `address_history[${addressIndex}].city`,
                                                            value: e.target.value
                                                        }
                                                    })
                                                }}
                                            />

                                            {/* {City.getCitiesOfState('US', values.address_history[addressIndex]?.state).length > 0 ? <Field
                                                name={`address_history[${addressIndex}].city`}
                                            >{({ field }) => (
                                                <Dropdown
                                                    id={field.name}
                                                    value={values.address_history[addressIndex]?.city}
                                                    options={City.getCitiesOfState('US', values.address_history[addressIndex]?.state)?.map((state) => ({
                                                        label: state.name,
                                                        value: state.name,
                                                    })) || []}
                                                    style={{ width: '100%' }}
                                                    onChange={(event) => handleChange(`address_history[${addressIndex}].city`)(event.value)}
                                                    onBlur={handleBlur}
                                                    placeholder="Select City"
                                                />
                                            )}</Field> : <Field type="text" id="city" name={`address_history[${addressIndex}].city`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />} */}
                                            <label htmlFor="city" style={{ color: Boolean(error?.city) ? 'red' : '' }}>City</label>
                                        </div>

                                    </FlexComponent>
                                    <div className='inputContainerDiv' style={{ width: '50%', margin: '0 0.5rem' }}>
                                        {/* <Field type="text" id="zip_code" name={`address_history[${addressIndex}].zip_code`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} /> */}
                                        <InputText style={{ width: '100%' }} defaultValue={values?.address_history[addressIndex]?.zip_code} id="zip_code" name={`address_history[${addressIndex}].zip_code`} onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name : `address_history[${addressIndex}].zip_code`,
                                                    value: e.target.value
                                                }
                                            })
                                        }}
                                        />
                                        <label htmlFor="zip_code" style={{ color: Boolean(error?.zip_code) ? 'red' : '' }}>Zip Code</label>
                                    </div>
                                </FlexComponent>

                                <FlexComponent style={{ width: '27%' }} flexDirection="column" alignItems="start" gap="0.5rem">
                                    <div className='inputContainerDiv' style={{ textAlign: 'start', margin: ' 0 0.5rem' }}>
                                        <label htmlFor="home_status" style={{ fontWeight: 'bold', color: Boolean(error?.home_status) ? 'red' : '' }}>Home Status *</label> <br />
                                        {["Renting", "Owning"].map((option) => {
                                            return (
                                                <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                                    <Field type="radio" id={option} name={`address_history[${addressIndex}].home_status`} value={option} as={RadioButton} />
                                                    <label htmlFor={option} className="ml-2">{option}</label>

                                                </FlexComponent >
                                            );
                                        })}
                                    </div>
                                    <div className='inputContainerDiv' style={{ textAlign: 'start', margin: '0.5rem' }}>
                                        <label style={{ fontWeight: 'bold', color: Boolean(error?.status) ? 'red' : '' }} htmlFor="status">Is this your current address? *</label> <br />
                                        {[{ label: "Yes", value: true }, { label: "No", value: false }].map((option) => {
                                            return (
                                                <FlexComponent justifyContent='start' gap='0.75rem' key={option.label} style={{ marginTop: '0.5rem' }}>
                                                    <Field type="radio" id={option.label} name={`address_history[${addressIndex}].status`} value={option.value} as={RadioButton} />
                                                    <label htmlFor={option.label} className="ml-2" >{option.label}</label>

                                                </FlexComponent >
                                            );
                                        })}
                                    </div>
                                </FlexComponent>
                            </FlexComponent>

                            <div style={{ textAlign: 'start', margin: '0.5rem' }}>
                                <label htmlFor="notes_about_address" >
                                    <p style={{ fontWeight: 'bold', marginTop: '1rem' }}> Notes about Address </p>
                                </label>
                                <Field rows={4} type="text" id="notes_about_address" name={`address_history[${addressIndex}].notes_about_address`} as={InputTextarea} style={{ width: '100%' }} />
                            </div>

                        </div>
                        <div style={{ position: 'sticky', bottom: '0', background: 'white' }}>
                            <FlexComponent gap='1rem' justifyContent={!isDelete ? 'end' : ''} style={{ padding: '1.5rem' }}>
                                {isDelete && <Button type='button' onClick={() => {
                                    // if (!isEdit) {
                                    arrayHelpers.remove(addressIndex);
                                    setIsEdit(false)
                                    setIsDelete(true)
                                    // }
                                    setIsEditOpen(false);
                                }}>Delete Address</Button>}
                                <Button type='button' label={isEdit ? 'Close' : 'Save'} onClick={handleSave} />
                            </FlexComponent>
                        </div>
                    </Dialog>
                )}
            </FieldArray>

        </div>
    )
}

export default AddressHistory