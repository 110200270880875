import React,{useState, useEffect, useRef} from 'react'; 
import { Card } from 'primereact/card';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import axios from '../../utils/axios';  
import { dashboardFilterData, dashboardReports, dashboardLeads, syncLocations, syncLocations_v2 } from '../../utils/urls';
import './dashboard.css'
import { useSelector } from 'react-redux'
import LeadsTable from '../leads/LeadsTable'
import { Calendar } from 'primereact/calendar';
import ChartJs from '../Chart/Chart';
import FlexComponent from '../FlexComponent';
import { Avatar } from 'primereact/avatar';
import { Dialog } from 'primereact/dialog';
import AppsTable from '../apps/appsTable';
import RepliesTable from '../apps/RepliesTable';

export default function ReportingDashboard() {
    const [isMounted, setIsMounted] = useState(false)
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const custom_user_id = useSelector((state) => state.user.userId);
    const [leadsCount, setLeadsCount] = useState(0)
    const [repliesCount, setRepliesCount] = useState(0)
    const [oppertunityCount, setOppertunityCount] = useState(0)
    const [preAppsCount, setPreAppsCount] = useState(0)
    const [shoppersCount, setShoppersCount] = useState(0)
    const [repliesConv, setRepliesConv] = useState(0)
    const [oppertunityConv, setOppertunityConv] = useState(0)
    const [preAppsConv, setPreAppsConv] = useState(0)
    const [outboundCall, setOutboundCall] = useState(0)
    const [manualCall, setManualCall] = useState(0)
    const [dialerCall, setDialerCall] = useState(0)
    const [appCount, setAppCount] = useState(0)
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedRange, setSelectedRange] = useState({"id": 1, "name": "DAY"});
    const [locations, setLocations] = useState(null)
    const [campaigns, setCampaigns] = useState(null)
    const [users, setUsers] = useState(null)
    const [languages, setLanguages] = useState(null)
    const [teams, setTeams] = useState(null)
    const [companies, setCompanies] = useState(null)
    const dateRange = [{"id": 1, "name": "DAY"}, {"id": 2, "name": "MONTH"}]
    const [leads, setLeads] = useState([]);
    const generateDateRange = () => {
        const today = new Date();
        const last7thDay = new Date();
        last7thDay.setDate(today.getDate() - 7);
    
        return [last7thDay, today];
      };
    const [dates, setDates] = useState(() => generateDateRange());
    // const [dates, setDates] = useState(null);
    const [leadsLoading, setLeadsLoading] = useState(true);
    const [chartLabel, setChartLable] = useState([]);
    const [chartLeads, setChartLeads] = useState([]);
    const [chartReplies, setChartReplies] = useState([]);
    const [chartOppertunities, setChartOppertunities] = useState([]);
    const [chartPreApps, setChartPreApps] = useState([]);
    const [chartShoppers, setChartShoppers] = useState([]);
    const [chartOutboundCalls, setChartOutboundCalls] = useState([]);
    const [chartAppLeads, setChartAppLeads] = useState([]);

    const [tableVisible, setTableVisible] = useState(false)
    const [repliesTableVisible, setRepliesTableVisible] = useState(false)

    const [popupTableData, setpopupTableData] = useState({
        app_count_list : [],
        docs_in_list : [],
        preapps_list : [],
        shoppers_list : [],
        total_replies_id_list : []
    })
    const [tableData, setTableData] = useState([])
    const [repliesTableData, setRepliesTableData] = useState([])
    const [popupHeader, setPopupHeader] = useState("")
    const [clickableCard, setClickableCard] = useState(false)
    // console.log(popupTableData)

    useEffect(()=>{
        if (custom_user_id){
            axios.post(dashboardFilterData, {custom_user_id: custom_user_id},{
                headers: {'Content-Type': 'application/json'},
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setLocations(response.data.locations)
                        setCampaigns(response.data.campaigns)
                        setUsers(response.data.users)
                        setLanguages(response.data.languages)
                        setTeams(response.data.teams)
                        setCompanies(response.data.companies)
                    }
                    })
                .catch((error)=>{
                    console.log({error})
                    
                })
        }
    },[dashboardFilterData, isMounted, custom_user_id])

    useEffect(() => {
        if (custom_user_id && (dates === null || (custom_user_id && dates && dates[1] != null))){
            setLeadsLoading(true);
            const localDates = dates?.map(date => new Date(date.getTime() - date.getTimezoneOffset() * 60000));
            const data = {
                'locations' : selectedLocations.map(location => ({ location_id: location.location_id, name: location.name })),
                'campaigns' : selectedCampaigns.map(campaign => ({ campaign_id: campaign.campaign_id, name: campaign.name })),
                'users' : selectedUsers.map(user => ({ user_id: user.user_id, name: user.name, image: user.image })),
                'languages' : selectedLanguages.map(language => ({ language_id: language.language_id, name: language.name })),
                'teams' : selectedTeams.map(team => ({ team_id: team.team_id, name: team.name })),
                'companies' : selectedCompanies.map(company => ({ company_id: company.company_id, name: company.name })),
                'date_range' : localDates,
                'custom_user_id': custom_user_id,
                'range' : selectedRange.name
            }

            axios.post(dashboardReports, data,{
                headers: {'Content-Type': 'application/json' },
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setLeadsCount(response.data.count_data.total_leads)
                        setRepliesCount(response.data.count_data.total_replies)
                        setOppertunityCount(response.data.docs_in_count)
                        setPreAppsCount(response.data.preapps_count)
                        setShoppersCount(response.data.shoppers_count)
                        setRepliesConv(response.data.count_data.replies_conv)
                        setOppertunityConv(response.data.count_data.opps_conv)
                        setPreAppsConv(response.data.count_data.preApps_conv)  
                        setOutboundCall(response.data.count_data.total_outbount_calls)
                        setManualCall(response.data.count_data.total_manual_calls)
                        setDialerCall(response.data.count_data.total_dialer_calls)
                        setLeads(response.data.leads_data)
                        setChartLable(response.data.chart_data.chart_label)
                        setChartLeads(response.data.chart_data.total_leads_list)
                        setChartReplies(response.data.chart_data.total_replies_list)
                        setChartOppertunities(response.data.chart_data.total_oppertunities_list)
                        setChartPreApps(response.data.chart_data.total_pre_apps_list)
                        setChartShoppers(response.data.chart_data.total_shopper_apps_list)
                        setChartOutboundCalls(response.data.chart_data.total_outbound_call_list)
                        setAppCount(response.data.app_count)
                        setChartAppLeads(response.data.chart_data.total_app_lead_list)
                        // setDocsInListData(response.data.chart_data.docs_in_list)
                        console.log("response.data.chart_data", response.data.chart_data)
                        setpopupTableData((prev)=> ({
                            ...prev,
                            app_count_list : response.data.app_count_list,
                            docs_in_list : response.data.docs_in_list,
                            preapps_list : response.data.preapps_list,
                            shoppers_list : response.data.shoppers_list,
                            total_replies_id_list : response.data.total_replies_id_list
                        }))
                        setClickableCard(true)
                        setLeadsLoading(false);              
                    }
                    })
                .catch((error)=>{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});          
                })
        }
      }, [selectedLocations, selectedCampaigns, selectedLanguages, selectedTeams, selectedCompanies, selectedUsers, custom_user_id, dates, selectedRange]);

      function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

      const userTemplate = (option) => {
        return (
            <FlexComponent justifyContent="start">
                <Avatar image={option.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(option?.name)}</span>} shape="circle" style={{ marginRight: '8px' }} />
                <span>{option.name}</span>
            </FlexComponent>
        );
    };

    //   useEffect(() => {
    //     if (custom_user_id){
    //         const data = {
    //             'locations' : selectedLocations.map(location => ({ location_id: location.location_id, name: location.name })),
    //             'campaigns' : selectedCampaigns.map(campaign => ({ campaign_id: campaign.campaign_id, name: campaign.name })),
    //             'users' : selectedUsers.map(user => ({ user_id: user.user_id, name: user.name })),
    //             'languages' : selectedLanguages.map(language => ({ language_id: language.language_id, name: language.name })),
    //             'custom_user_id': custom_user_id,
    //             'date_range' : dates,
    //         }
    //         setLeadsLoading(true);
    //         axios.post(dashboardLeads, data,{
    //             headers: {'Content-Type': 'application/json' },
    //         })
    //             .then((response) => {
    //                 if (response.status === 200) {
    //                     setLeads(response.data)
    //                     setLeadsLoading(false);             
    //                 }
    //                 })
    //             .catch((error)=>{
    //                 toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});          
    //             })
    //     }
    //   }, [selectedLocations, selectedCampaigns, selectedLanguages, selectedUsers, custom_user_id, dates]);

    const syncHandle = () =>{
        setLoading(true);
        axios.get(syncLocations_v2, {
            headers: {'Content-Type': 'multipart/form-data'},
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                    setLoading(false);
                    }
                })
            .catch((error)=>{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                setLoading(false);
            })
    }


    return (
        <>
        <Toast ref={toast} />
        <FlexComponent className="applicantContainer" justifyContent="end" gap="2rem" >

        <div  className='sync-button-container '  style={{width: "200px"}}>
            <span className="p-float-label filter-card-container" >
                <MultiSelect value={selectedTeams} filter onChange={(e) => setSelectedTeams(e.value)} options={teams} optionLabel="name" maxSelectedLabels={1} className="w-full md:w-20rem full-width" />
                <label htmlFor="ms-cities">Select Teams</label>
            </span>
        </div>

        <div className='sync-button-container'  style={{width: "200px"}}>
            <span className="p-float-label filter-card-container">
                <MultiSelect value={selectedCompanies} filter onChange={(e) => setSelectedCompanies(e.value)} options={companies} optionLabel="name" maxSelectedLabels={1} className="w-full md:w-20rem full-width" />
                <label htmlFor="ms-cities">Select Companies</label>
            </span>
        </div>
        
        <div className='sync-button-container'>
            <Button className='sync-button' label="Sync" icon="pi pi-sync" onClick={syncHandle} />
        </div>
        </FlexComponent>
        
        {loading && (
            <div className="overlay"> 
                <i className="pi pi-spin pi-spinner " style={{ fontSize: '2rem' }}></i>
            </div>
        )}

        <div className='dashboard-filter-container'>
            <span className="p-float-label filter-card-container">
                <MultiSelect value={selectedLocations} filter onChange={(e) => setSelectedLocations(e.value)} options={locations} optionLabel="name" maxSelectedLabels={1} className="w-full md:w-20rem full-width" />
                <label htmlFor="ms-cities">Select Locations</label>
            </span>

            <span className="p-float-label filter-card-container">
                <MultiSelect value={selectedCampaigns} filter onChange={(e) => setSelectedCampaigns(e.value)} options={campaigns} optionLabel="name" maxSelectedLabels={1} className="w-full md:w-20rem full-width" />
                <label htmlFor="ms-cities">Select Campaigns</label>
            </span>

            <span className="p-float-label filter-card-container">
                <MultiSelect value={selectedUsers} filter onChange={(e) => setSelectedUsers(e.value)} options={users} itemTemplate={userTemplate} optionLabel="name" maxSelectedLabels={1} className="w-full md:w-20rem full-width" />
                <label htmlFor="ms-cities">Select Users</label>
            </span>

            <span className="p-float-label filter-card-container">
                <MultiSelect value={selectedLanguages} filter onChange={(e) => setSelectedLanguages(e.value)} options={languages} optionLabel="name" maxSelectedLabels={1} className="w-full md:w-20rem full-width" />
                <label htmlFor="ms-cities">Select Languages</label>
            </span>

            

            <span className="p-float-label filter-card-container">
                <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" className="full-width"/>
                <label htmlFor="ms-cities">Select Date Range</label>
            </span>
        </div>

        <div className='dashboard-card-container'>
        <div className="dash-card-container flex justify-content-center">
            <Card title="Total Leads" subTitle={`${leadsCount}`} className="md:w-25rem dashboard-card leads-card">              
            </Card>
        </div>

        <div className="dash-card-container flex justify-content-center">
            <Card title="Total Replies" style={{pointerEvents: clickableCard ? "all" : "none", cursor : "pointer"}} onClick={()=>{
                setRepliesTableVisible(true)
                setPopupHeader("Total Replies")
                setRepliesTableData(popupTableData.total_replies_id_list)
            }} subTitle={`${repliesCount}`} className="md:w-25rem dashboard-card replies-card">
            <p className="dashboard-percentage">{`${repliesConv}%`}</p>          
            </Card>
        </div>

        <div className="dash-card-container flex justify-content-center">
            <Card title="Total Calls" subTitle={`${outboundCall}`} className="md:w-25rem dashboard-card call-card">
            <span className="dashboard-percentage" style={{display:'flex', flexDirection:'column', alignItems:'center', gap:'.5rem'}}>
                <p>Manual</p>
                <p>{`${manualCall}`}</p>
            </span>
            <span className="dashboard-percentage" style={{display:'flex', flexDirection:'column', alignItems:'center', gap:'.5rem'}}>
                <p>Dialer</p>
                <p>{`${dialerCall}`}</p>
            </span> 
            </Card>
        </div>

        <div className="dash-card-container flex justify-content-center">
            <Card title="Total Apps" style={{pointerEvents: clickableCard ? "all" : "none", cursor : "pointer"}} onClick={()=>{
                setTableVisible(true)
                setPopupHeader("Total Apps")
                setTableData(popupTableData.app_count_list)
            }} subTitle={`${appCount}`} className="md:w-25rem dashboard-card app-card">
            </Card>
        </div>

        <div className="dash-card-container flex justify-content-center">
            <Card title="Total Docs In" style={{pointerEvents: clickableCard ? "all" : "none", cursor : "pointer"}} onClick={()=>{
                setTableVisible(true)
                setPopupHeader("Total Docs In")
                setTableData(popupTableData.docs_in_list)
            }} subTitle={`${oppertunityCount}`} className="md:w-25rem dashboard-card opp-card"> 
            {/* <p className="dashboard-percentage">{`${oppertunityConv}%`}</p> */}
            </Card>
        </div>

        <div className="dash-card-container flex justify-content-center">
            <Card title="Total PreApps" style={{pointerEvents: clickableCard ? "all" : "none", cursor : "pointer"}} onClick={()=>{
                setTableVisible(true)
                setPopupHeader("Total PreApps")
                setTableData(popupTableData.preapps_list)
            }} subTitle={`${preAppsCount}`} className="md:w-25rem dashboard-card pre-card">
            {/* <p className="dashboard-percentage">{`${preAppsConv}%`}</p> */}
            </Card>
        </div>

        <div className="dash-card-container flex justify-content-center">
            <Card title="Total Shoppers" style={{pointerEvents: clickableCard ? "all" : "none", cursor : "pointer"}} onClick={()=>{
                setTableVisible(true)
                setPopupHeader("Total Shoppers")
                setTableData(popupTableData.shoppers_list)
            }} subTitle={`${shoppersCount}`} className="md:w-25rem dashboard-card shoppers-card">
            </Card>
        </div>

        <Dialog header={popupHeader} className='popupTableData' visible={tableVisible} modal={false} style={{ width: '50vw' }} onHide={() => {if (!tableVisible) return; setTableVisible(false); }}>
            <div>
                {tableData?.length > 0 && <AppsTable userId={custom_user_id} countsIds = {tableData}/>}
            </div>
        </Dialog>

        <Dialog header={popupHeader} className='popupTableData' visible={repliesTableVisible} modal={false} style={{ width: '50vw' }} onHide={() => {if (!repliesTableVisible) return; setRepliesTableVisible(false); }}>
            <div>
                {repliesTableData?.length > 0 && <RepliesTable userId={custom_user_id} countsIds = {repliesTableData}/>}
            </div>
        </Dialog>

        </div>

        <div style={{marginTop:'4rem'}}>
            <span className="p-float-label filter-card-container" style={{display: 'flex', justifyContent: 'end'}}>
                <Dropdown value={selectedRange} onChange={(e) => setSelectedRange(e.value)} options={dateRange} optionLabel="name" 
                placeholder="Select a Range" className="w-full md:w-14rem" style={{width:"135px", marginBottom: "1rem"}} />
            </span>
           <ChartJs label={chartLabel} leads={chartLeads} replies={chartReplies} oppertunities={chartOppertunities} preApps={chartPreApps} shoppers={chartShoppers} outboundCalls={chartOutboundCalls} chartAppLeads={chartAppLeads} loading={leadsLoading}/>
        </div>
        
        <div style={{marginTop:'4rem', marginBottom:'2rem'}}>
            <LeadsTable leads={leads} loading={leadsLoading}/>
        </div>
        </>
    )
}