import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios'
import Swal from 'sweetalert2';
import { login } from '../../utils/urls';
import jwt_decode from "jwt-decode";
import { useDispatch } from 'react-redux';
import { setUser } from '../../utils/userSlice';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import FlexComponent from '../FlexComponent';
import googleImage from '../../Images/googleImage.png'


function LoginForm() {

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (localStorage.getItem("authTokens")) {
      const prevUrl = localStorage.getItem("prevUrl");
      navigate(prevUrl);
    }
  }, [navigate])

  const handleLogin = (e) => {
    e.preventDefault();
    if (!username) {
      setUsernameError('Username is required');
    }
    else {
      setUsernameError('');
    }
    if (!password) {
      setPasswordError('Password is required');
    }
    else {
      setPasswordError('');
    }
    if (username && password) {

      const body = JSON.stringify({
        username,
        password,
      });

      axios
        .post(login, body, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {

          if (response.status === 200) {
            const decoded_token = jwt_decode(response.data.access)

            localStorage.setItem('authTokens', JSON.stringify(response.data))
            dispatch(setUser({ userId: decoded_token.user_id, isSuperuser: decoded_token.is_superuser }));
            navigate("/");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Login Successfully",
              showConfirmButton: false,
              timer: 1500,
            });

          }
          else {
            Swal.fire({
              position: "center",
              icon: "warning",
              title: "Invalid Credentials",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }).catch((err) => {
          console.log(err)
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "Invalid Credentials",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  }


  return (
    <FlexComponent justifyContent="center" >
      <form onSubmit={handleLogin} style={{zIndex: "1"}} >
        {/* <h2 style={{ color: "white"}}>Hello Again!</h2> */}
        <p style={{ fontSize: '18px', color: "white" }}>Log in to breadcrumbs</p>
        <div style={{ marginTop: '1rem' }}>
          <div className="p-inputgroup flex-1 login-input-div" style={{ width: '400px' }}>
            {/* <span className="p-inputgroup-addon" style={{background: 'white'}}>
              <i className="pi pi-user"></i>
            </span> */}
            <InputText
              // style={{background: 'rgba(255, 255, 255, 0.06)', boxShadow: '-2px -2px 0px',  border:"none", filter: 'blur(0px)', backdropFilter: 'blur(10px)'}}
              placeholder="Username"
              required
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="p-inputgroup flex-1 login-input-div" style={{ width: '400px', marginTop: '1rem' }}>
            {/* <span className="p-inputgroup-addon" style={{background: 'white'}}>
            <i className="pi pi-lock"></i>
          </span> */}
            <Password
              placeholder="Password"
              feedback={false}
              value={password}
              // style={{background: 'rgba(255, 255, 255, 0.06)', boxShadow: '-2px -2px 0px',  border:"none", filter: 'blur(0px)', backdropFilter: 'blur(10px)'}}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {/* <div className="input-field">
            <i className="fas fa-user"></i>
            <input
              type="text"
              placeholder="Username"
              required
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div> */}
          {/* <div className="input-field">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div> */}
        </div>


        <input className='input-submit'
          type="submit"
          value="Login"
          // className="btn solid"
          style={{ width: '400px', cursor: 'pointer', background: 'white', color: 'black', fontSize: '16px', fontWeight: '500', padding: '10px', border: 'none', marginTop: '1rem', borderRadius: '5px' }}
        />

        <hr class="hr-text" />

        {/* <p>Login with <a className='otp-link' onClick={()=> setOtpLogin(!otpLogin)}>{!otpLogin ? 'OTP' : 'CREDENTIALS'}</a></p> */}
        <div style={{fontSize: "14px", color: "white", width: "100%", textAlign: "center", border: "1px #333333 solid", padding: "1rem", borderRadius: "10px" }} >Don’t have an account? Sign Up →</div>
        <div style={{ color: "#333333", width: "100%", textAlign: "center", padding: "1rem", borderRadius: "10px" }} >or</div>
        <div style={{ width: "100%", textAlign: "center"}}>
          <img src={googleImage} width={60} alt="" />
        </div>

      </form>

    </FlexComponent>
  )
}

export default LoginForm
