import { useRef, useState, useEffect } from 'react';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableAppItem from './SortableUserItem';
import { ProgressSpinner } from 'primereact/progressspinner';
import FlexComponent from '../../FlexComponent';
import axios from '../../../utils/axios';
import { Dialog } from 'primereact/dialog';
import './apps.css'
import { appSubmission, getCustomFields, getOpportunitiesListByStage, getUserOpportunitiesListByStage, getUserOpportunityCard, toggleStageWatcher } from '../../../utils/urls';
import UserPopup from './UserPopup';
import UserItem from './UserItem';


function mergeRefs(...refs) {
  return (element) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    });
  };
}



const UserSection = ({ userId, toast, id, pipeline, apps, usersList, stageIds, stageAppCounts, setTotal, setBoardSections, searchQuery, loading }) => {
  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id,
  });
  const customRef = useRef(null);
  // const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false)
  const [popupLoad, setPopupLoad] = useState(false)
  const previousOffsetRef = useRef(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [sort, setSort] = useState("DESC")

  const [isOpen, setIsOpen] = useState(false);
  const [applicantData, setApplicantData] = useState()

  // const [stageToggle, setStageToggle] = useState(stageWatchers[id]?.includes(userId))

  const handleClick = (formId) => {
    setIsOpen(true);
    setPopupLoad(true)
    const url = `${getUserOpportunityCard}${formId}?pipelineId=${pipeline.id}`
    axios.get(url, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          const data = response.data
          setApplicantData(data)
          setPopupLoad(false)
        }
      })
      .catch((error) => {
        // setAppLeads(null)
      })
  };



  const handleClose = () => {
    setIsOpen(false);
  };



  useEffect(() => {
    const handleScroll = () => {
      if (customRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = customRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1 && !scrollLoading && !isEmpty) {
          const currentOffset = apps.length;

          if (currentOffset === previousOffsetRef.current) {
            return; // No change in offset, so do not make the API call
          }
          

          setScrollLoading(true);
          const url = `${getUserOpportunitiesListByStage}?userId=${userId}&stageId=${stageIds[id]}&offset=${currentOffset}&limit=5&search=${searchQuery || ''}&sort=${sort}`
          axios.get(url, {
            headers: { 'Content-Type': 'application/json' },
          })
            .then((response) => {
              if (response.status === 200) {
                const dataT = response.data;
                // console.log('dataT', dataT)
                const updatedData = Object.keys(dataT).reduce((acc, key) => ({
                  ...acc,
                  [key]: dataT[key]?.map(item => ({ ...item, id: item.user_id }))
                }), {});

                const convertedArray = Object.values(updatedData).flat();
                if (convertedArray.length === 0) {
                  setIsEmpty(true);
                }
                // console.log('convertedArray', convertedArray)

                if (convertedArray.length !== 0) {
                  setBoardSections(prevState => {
                    const existingApps = prevState[id];
                    const newApps = convertedArray.filter(newApp =>
                      !existingApps?.some(existingApp => existingApp.user_id === newApp.user_id)
                    );
                    return {
                      ...prevState,
                      [id]: [
                        ...existingApps,
                        ...newApps
                      ]
                    };
                  });
                  previousOffsetRef.current = currentOffset; // Update the previous offset
                }

              }
              setScrollLoading(false);
            })
            .catch(() => {
              setScrollLoading(false);
            });
        }
      }
    };

    const currentRef = customRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [id, apps, setBoardSections, scrollLoading, sort, isEmpty]);


  // console.log(id, "id")
  const fetchSortedData = (sortDirection) => {

    // Set loading state to true
    setScrollLoading(true);

    const url = `${getUserOpportunitiesListByStage}?userId=${userId}&stageId=${stageIds[id]}&offset=0&limit=5&search=${searchQuery || ''}&sort=${sortDirection}`;

    axios
      .get(url, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        if (response.status === 200) {
          const dataT = response.data;

          const updatedData = Object.keys(dataT).reduce(
            (acc, key) => ({
              ...acc,
              [key]: dataT[key]?.map((item) => ({ ...item, id: item.user_id })),
            }),
            {}
          );

          const convertedArray = Object.values(updatedData).flat();

          // Replace the existing data with the newly fetched data
          setBoardSections((prevState) => ({
            ...prevState,
            [id]: convertedArray,
          }));
        }
        previousOffsetRef.current = 0
        // Set loading state to false
        setScrollLoading(false);
      })
      .catch(() => {
        setScrollLoading(false);
      });
  };

  // console.log("iddddddddd", id, stageIds[id])

  const toggleStageWatcherFn = async (stageId)=>{
    try {
      const data = {
        stage_id: stageId,
        user_id: userId
      }
      // setStageToggle(!stageToggle)
      // console.log(data)
      const response = await axios.post(toggleStageWatcher, data);
      if (response.status === 200) {
          // console.log('Order updated successfully');
          toast.current.show({ severity: 'success', summary: '', detail: 'Success', life: 3000 });

      } else {
          console.error('Failed to update order');
      }
  } catch (error) {
      console.error('Error updating order:', error);
  }
  }

  return (
    <div style={{ borderRadius: '15px',  padding: "20px 10px", minWidth: '350px' }} className='applicantCardSectionDiv'>
      <p variant="h6" style={{ color: 'white', marginBottom: '10px', whiteSpace: 'nowrap' }}>
        {/* <FlexComponent  >
          <p>{id}</p>
          <p style={{ fontSize: '18px' }}>{stageAppCounts[id] || 0}</p>
        </FlexComponent> */}
        {loading ? <div className='loadingAppItem' style={{ width: '100%', height: '20px', padding: "0px ", margin: "0px 5px", border: "none" }}>
        </div> :
          <FlexComponent>
            <p>{!loading && id}</p>
            {/* <i style={{cursor: "pointer"}} className= {stageToggle ? 'pi pi-eye' : 'pi pi-eye-slash'} onClick={()=>{
                  toggleStageWatcherFn(stageIds[id])
            }} ></i> */}
            <i
              onClick={() => {
                const newSortDirection = sort === 'ASC' ? 'DESC' : 'ASC';
                setSort(newSortDirection);
                // Clear the current apps data
                setBoardSections((prevState) => ({
                  ...prevState,
                  [id]: [],
                }));
                // Call the API with the new sort direction and reset offset to 0
                fetchSortedData(newSortDirection);
              }}
              className= {sort === "ASC" ? "pi pi-sort-up" : "pi pi-sort-down"}
              style={{cursor: "pointer"}}
            >
              {/* {sort === "ASC" ? <i className="pi pi-sort-up" style={{ fontSize: '1.25rem', cursor:"pointer" }}></i> : <i className="pi pi-sort-down" style={{ fontSize: '1.25rem', cursor:"pointer" }}></i>} */}
            </i>
            <p style={{ fontFamily: "DINCondensedC", fontSize: '18px', height: "20px", }}>{!loading && stageAppCounts[id]}</p>
          </FlexComponent>
        }
      </p>
      <SortableContext
        id={id}
        items={apps}
        strategy={verticalListSortingStrategy}
      >
        <div className='appSection' ref={mergeRefs(setDroppableNodeRef, customRef)} style={{ overflowY: 'scroll', height: '65vh' }}>
          {!loading && apps?.map((app) => (
            <div key={app?.id} style={{ margin: '10px' }} onClick={() => {
              handleClick(app.id)
            }}>
              <SortableAppItem id={app?.id} >
                <UserItem app={app} userId={userId} setBoardSections={setBoardSections} setTotal={setTotal} toast={toast} />
              </SortableAppItem>
            </div>
          ))}

          {loading &&
            (Array(4).fill("").map((item, index) => (
              <div key={index} className='loadingAppItem' style={{ width: '280px', height: '170px' }}>
              </div>
            )))
          }
          {scrollLoading &&
            <FlexComponent justifyContent="center">
              <ProgressSpinner style={{ width: '40px', height: '40px' }} />
            </FlexComponent>
          }
        </div>
      </SortableContext>
      <Dialog className='applicantPopup ' visible={isOpen} onHide={() => handleClose()}>
        <div style={{ marginTop: '1rem', minHeight: "70vh" }}>
          {!popupLoad ? <UserPopup applicantData={applicantData} pipelineId={pipeline.id} usersList={usersList} userId={userId} setBoardSections={setBoardSections} handleClick={handleClick} handleClose={handleClose} toast={toast} /> :
            <FlexComponent flexDirection="column" justifyContent="center" >
              <div style={{ minWidth: "40vw", height: "100%" }} className='loadingAppItem' ></div>
            </FlexComponent>
          }
        </div>
      </Dialog>
    </div>
  );
};

export default UserSection;
