import { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ToggleButton } from 'primereact/togglebutton';
import { campaignList, lockCampaignUrl } from '../../utils/urls';   
import axios from '../../utils/axios';       
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

export default function CampaignTable() {
    const navigate = useNavigate()
    const toast = useRef(null);
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        campaign_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        sub_zap_id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const dt = useRef(null);

    useEffect(()=>{
        // setLoading(true);
        axios.get(campaignList, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(response.data);
                    setLoading(false);
                    // initFilters();
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[campaignList, isMounted])

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const userView = (rowData) => {
        const campaign_id = rowData.campaign_id
        return <Button className='view-button' label="View" icon="pi pi-external-link" onClick={()=>redirectUserPage(campaign_id)} />
    };

    const fallbackUserView = (rowData) => {
        const campaign_id = rowData.campaign_id
        return <Button className='view-button' label="View" icon="pi pi-external-link" onClick={()=>redirectFallbackUserPage(campaign_id)} />
    };

    const redirectUserPage = (campaign_id) =>{
        navigate(`/campaign_users/${campaign_id}`)
    }

    const redirectFallbackUserPage = (campaign_id) =>{
        navigate(`/campaign_fallback_users/${campaign_id}`)
    }

    const accept = (lockData) => {
        axios.post(lockCampaignUrl, lockData,{
        headers: {'Content-Type': 'multipart/form-data'},
        })
        .then((response) => {
            if (response.status === 200) {
                setIsMounted(!isMounted);
                toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            })
        .catch((error)=>{

            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            
        })
    }

    const reject = () => {
        console.log('closed')
    }

    // const lockTemplate = (rowData) => {
    //     const status = rowData.is_paused
    //     const campaign_id = rowData.campaign_id
    //     const icon = rowData.is_paused ? 'pi pi-lock' : 'pi pi-lock-open';

    //     return <Button type="button" icon={icon} className={status?"p-button-sm p-button-text red-lock": 'p-button-sm p-button-text'} onClick={() => handlePauseCampaign(campaign_id, !status)} />;
    // };

    const lockTemplate = (rowData) => {
        const status = rowData.is_paused
        const campaign_id = rowData.campaign_id

        return <ToggleButton onLabel="OFF" offLabel="ON" checked={status} onChange={(e) => handlePauseCampaign(campaign_id, !status)} className="w-8rem" />;
    };

    const handlePauseCampaign = (campaign_id, status) => {

        const lockData = {
            campaign_id,
            "status": status
        }

        let message;
        if (status===true){
            message = 'Are you sure you want to pause this campaign?'
        }
        else{
            message = 'Are you sure you want to make this campaign live?'
        }
        confirmDialog({
            message: message,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(lockData),
            reject
        });
        
    }

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className='header-container-with-csv-export'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
                <div className="flex align-items-center justify-content-end gap-2">
                    <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                </div>
            </div>
        );
    };

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };

    const header = renderHeader();

    return (
        <div className="card">
            <Toast ref={toast} />
            <ConfirmDialog />
            <DataTable ref={dt} value={customers} paginator rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)}
                    dataKey="id"
                    stateStorage="session" stateKey="dt-state-demo-local" emptyMessage="No campaigns found." tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ width: '2rem'  }} body={srNoBodyTemplate} />
                <Column field="campaign_name" header="Campaign" sortable style={{ width: '40%' }}></Column>
                <Column field="remaining" header="Remaining" style={{ width: '20%' }} sortable></Column>
                {/* <Column field="sub_zap_id" header="Sub Zap ID" sortable style={{ width: '30%' }}></Column> */}
                <Column header="Users" body={userView} style={{ width: '20%' }}></Column>
                <Column header="Fallback Users" body={fallbackUserView} style={{ width: '20%' }}></Column>
                <Column body={lockTemplate} style={{ width: '2rem'  }}></Column>
            </DataTable>
        </div>
    );
}