import React, { useEffect, useRef, useState } from 'react';
import FlexComponent from '../../FlexComponent';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import './apps.css'
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useSelector } from 'react-redux';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import axios from '../../../utils/axios';

import { archiveSubmission, deleteSubmission } from '../../../utils/urls';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

const UserItem = ({ app, userId, toast, setTotal, setBoardSections }) => {
    const navigate = useNavigate()
    const dotsOp = useRef(null);
    // const otherCustomFields = getValuesByNames(app?.customFields, ["Loan Amount", "Purchase Price"])
    // console.log(otherCustomFields)
    const isSuperuser = useSelector((state) => state.user.isSuperuser)
    const redirectProfilePage = (contact_id, user_id) => {
        navigate(`/app/${contact_id}/${user_id}`)
    }

    function changeIncomeColor(applicantIncome) {
        const income = parseInt(applicantIncome?.replace(/\D/g, ''));
        // console.log(income)
        if (isNaN(income)) return '';
        if (income >= 80) {
            return '#2FC623';
        } else if (income < 80 && income >= 65) {
            return 'orange';
        } else {
            return '#EB4243'
        }
    }

    function changeCreditColor(applicantCredit) {
        const credit = parseInt(applicantCredit);
        // console.log(credit)
        if (isNaN(credit)) return '';
        if (credit >= 620) {
            return '#2FC623';
        } else if (credit < 620 && credit >= 500) {
            return '#F7DF01';
        } else {
            return 'red'
        }
    }

    function changeAvailableColor(cashAvailable) {
        const amount = parseInt(cashAvailable?.replace(/\D/g, ''));
        // console.log(amount)
        if (isNaN(amount)) return '';
        if (amount >= 45) {
            return '#2FC623';
        } else if (amount < 45 && amount >= 20) {
            return '#FF9A00';
        } else {
            return 'red'
        }
    }

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    function formatDate(inputDate) {
        const dateParts = inputDate.split(' ')[0].split('-');
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        const date = new Date(`${year}-${month}-${day}`);

        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];
        const formattedDate = `${parseInt(day)} ${monthNames[date.getMonth()]}, ${year}`;
        return formattedDate;
    }

    const handleArchiveCard = (event) => {
        // setLoading(true)
        confirmPopup({
            message: 'Are you sure you want to Archive this card?',
            target: event.currentTarget,
            group: 'headless',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setBoardSections(prevSections => {
                    const updatedSections = { ...prevSections };
                    for (const sectionId in updatedSections) {
                        updatedSections[sectionId] = updatedSections[sectionId].filter((item) => item.user_id !== app.user_id)
                    }
                    return updatedSections;
                });
                const Url = `${archiveSubmission}${app.user_id}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            // setLoading(false)
                            toast.current.show({ severity: 'success', summary: 'SuccessFully Archived', detail: '', life: 3000 });
                            setTotal((prev) => prev - 1)
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        // handleClose()
                        // setLoading(false)
                    })

            },
            reject: () => {
                console.log("closed")
            }
        });
    };

    const handleDeleteCard = (event) => {
        // setLoading(true)
        confirmPopup({
            message: 'Are you sure you want to Delete this card?',
            target: event.currentTarget,
            group: 'headless',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setBoardSections(prevSections => {
                    const updatedSections = { ...prevSections };
                    for (const sectionId in updatedSections) {
                        updatedSections[sectionId] = updatedSections[sectionId].filter((item) => item.user_id !== app.user_id)
                    }
                    return updatedSections;
                });
                const Url = `${deleteSubmission}${app.user_id}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            // setLoading(false)
                            toast.current.show({ severity: 'success', summary: 'SuccessFully Deleted', detail: '', life: 3000 });
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        // setLoading(false)
                    })

            },
            reject: () => {
                console.log("closed")
            }
        });
    };

    function convertDate(utcDateString) {
        if(!utcDateString) return  ""
        const utcDate = new Date(utcDateString);

        const dateOptions = {
            day: '2-digit',
            month: 'short',
            // year: 'numeric',
        };

        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        const formattedDate = utcDate.toLocaleDateString('en-GB', dateOptions);
        // const formattedTime = utcDate.toLocaleTimeString('en-US', timeOptions);

        const formattedLocalDateTime = `${formattedDate}`;

        // console.log(formattedLocalDateTime);
        return formattedLocalDateTime

    }

    function isDateDue(utcDateString) {
        const utcDate = new Date(utcDateString);
        const now = new Date();
        return utcDate < now;
      }



      function formatNumber(number) {
        // Convert the input to a number
        if (number) {
            const num = parseInt(number, 10);

            if (isNaN(num)) {
                throw new Error('Invalid number');
            }

            if (num >= 1000 && num < 1000000) {
                return (num / 1000).toFixed(1) + 'k';
            } else if (num >= 1000000 && num < 1000000000) {
                return (num / 1000000).toFixed(1) + 'M';
            } else if (num >= 1000000000) {
                return (num / 1000000000).toFixed(1) + 'B';
            } else {
                return num.toString();
            }
        }
    }

    return (
        <div className='appItem' style={{boxShadow: "-1px -2px 0px #ffffff40",  padding: '10px', cursor: 'pointer', background: 'rgba(255, 255, 255, 0.06)' }}>
            <FlexComponent flexDirection='column' alignItems='start'>
                <FlexComponent justifyContent="end" gap=".3rem" >
                    
                </FlexComponent>
                <FlexComponent  alignItems='start'>
                    <FlexComponent flexDirection="column" alignItems="start" gap=".3rem" style={{ width: "100%" }}>
                        <FlexComponent justifyContent="start"  >
                            <Avatar image={app?.image} size="small"label={<span style={{ padding: '5px' }}>{getInitials(app?.full_name)}</span>}  shape="circle" style={{ marginRight: '8px' }} />
                            <p style={{ fontSize: '18px' }}>{app?.full_name}</p>
                        </FlexComponent>
                        <p style={{ fontSize: '14px' }}><span style={{color: "rgb(189 189 189)"}} >Email: </span>{app?.email}</p>
                        <p style={{ fontSize: '14px' }}><span style={{color: "rgb(189 189 189)"}} >Phone: </span>{app?.phone}</p>
                        <p style={{ fontSize: '14px' }}><span style={{color: "rgb(189 189 189)"}} >Designation: </span>{app?.designation}</p>
                        <p style={{ fontSize: '14px' }}><span style={{color: "rgb(189 189 189)"}} >Company: </span>{app?.company}</p>
                    </FlexComponent>
                    {/* <FlexComponent style={{ width: "30%" }} justifyContent="end" >
                        {isSuperuser && <div onClick={(e) => {
                            e.stopPropagation()
                            dotsOp.current.toggle(e)
                        }}>

                            <OverlayPanel onClick={(e) => {
                                e.stopPropagation()
                            }} ref={dotsOp} style={{ background: '#1A2B53' }} >
                                <FlexComponent gap="1rem" >
                                    <ConfirmPopup />
                                    <Button onClick={(e) => {
                                        e.stopPropagation()
                                        handleArchiveCard(e)
                                    }} >Archive</Button>
                                    <Button onClick={(e) => {
                                        e.stopPropagation()
                                        handleDeleteCard(e)
                                    }}>Delete</Button>
                                </FlexComponent>
                            </OverlayPanel>
                        </div>}
                    </FlexComponent> */}
                </FlexComponent>

            </FlexComponent>

        </div>
    );
};

export default UserItem;
