import { useState } from 'react';
// import Grid from '@mui/material/Grid';
import {
    useSensors,
    useSensor,
    PointerSensor,
    KeyboardSensor,
    DndContext,
    closestCorners,
    DragOverlay,
    defaultDropAnimation,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates, arrayMove } from '@dnd-kit/sortable';
import FlexComponent from '../../FlexComponent';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import UserItem from './UserItem';
import UserSection from './UserSection';

const findBoardSectionContainer = (boardSections, id) => {
    if (id in boardSections) {
        return id;
    }

    const container = Object.keys(boardSections).find((key) =>
        boardSections[key].find((item) => item.user_id === id)
    );
    return container;
};

const UserSectionList = ({ userId, toast, boardSections, pipeline, usersList, selectedUsers, selectedApplicationByUsers,  stageAppCounts, stageIds,  setTotal, setStageAppCounts, setBoardSections, handleStageUpdate, searchQuery, isMounted, setIsMounted, loading }) => {
    const [activeAppId, setActiveAppId] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 1 }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = ({ active }) => {
        setActiveAppId(active.id);
    };
    // console.log(boardSections)

    const getAppById = (apps, id) => {
        for (const itemId in apps) {
            const items = apps[itemId];
            for (const item of items) {
                if (item.id === id) {
                    return item;
                }
            }
        }
    };



    const handleDragOver = ({ active, over }) => {
        const activeContainer = findBoardSectionContainer(boardSections, active.id);
        const overContainer = findBoardSectionContainer(boardSections, over?.id);

        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return;
        }

        // console.log('handleDragOver','||' ,'activeContainer: ', activeContainer , 'overContainer: ',overContainer)

        setBoardSections((boardSection) => {
            // console.log(boardSection)
            const activeItems = boardSection[activeContainer];
            const overItems = boardSection[overContainer];
            const activeIndex = activeItems.findIndex((item) => item.id === active.id);
            const overIndex = overItems.findIndex((item) => item.id !== over?.id);
            // console.log('handleDragOver ||', 'activeIndex: ', activeIndex, 'overIndex: ', overIndex )
            return {
                ...boardSection,
                [activeContainer]: [
                    ...boardSection[activeContainer].filter((item) => item.id !== active.id),
                ],
                [overContainer]: [
                    ...boardSection[overContainer].slice(0, overIndex),
                    boardSections[activeContainer][activeIndex],
                    ...boardSection[overContainer].slice(overIndex, boardSection[overContainer].length),
                ],
            };
        });
    };

    const handleDragEnd = ({ active, over }) => {
        // console.log('handleDragEnd','||' ,'active: ', active , 'over: ',over)
        const activeContainer = findBoardSectionContainer(boardSections, active.id);
        const overContainer = findBoardSectionContainer(boardSections, over?.id);

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return;
        }

        // console.log('handleDragEnd','||' ,'activeContainer: ', activeContainer , 'overContainer: ',overContainer)

        const activeIndex = boardSections[activeContainer].findIndex((app) => app.id === active.id);
        const overIndex = boardSections[overContainer].findIndex((app) => app.id === over?.id);
        if (activeIndex !== overIndex) {
            setBoardSections((boardSection) => ({
                ...boardSection,
                [overContainer]: arrayMove(boardSection[overContainer], activeIndex, overIndex),
            }));
        }
        // console.log(app)
        if (app.app_status != overContainer) {
            // console.log("app.app_status", app.app_status)
            // console.log("overContainer122", overContainer)
            handleStageUpdate(app.user_id, overContainer, app.app_status)
        }

        const updateStatusInSection = (section, itemId, newStatus) => {
            setBoardSections((prevBoardSections) => {
                if (section in prevBoardSections) {
                    const sectionIndex = prevBoardSections[section]?.findIndex((item) => item?.id === itemId);
                    if (sectionIndex !== -1) {
                        const updatedSection = [...prevBoardSections[section]];
                        updatedSection[sectionIndex].app_status = newStatus;
                        return {
                            ...prevBoardSections,
                            [section]: updatedSection,
                        };
                    }
                }
                return prevBoardSections;
            });
        };
        updateStatusInSection(overContainer, app.id, overContainer)
        setActiveAppId(null);
    };

    //   const dropAnimation = defaultDropAnimation;

    const app = activeAppId ? getAppById(boardSections, activeAppId) : null;

    return (
        <div >
            {/* <ConfirmDialog /> */}

            <DndContext
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
            >
                <FlexComponent gap='2rem' style={{ color: 'black', width: '100%', padding: '0 15px 15px 15px' }} className='applicantCardInnerDiv' alignItems='start' container spacing={4}>
                    {Object?.keys(boardSections)?.map((boardSectionKey) => (
                        <FlexComponent item key={boardSectionKey} >
                            <UserSection
                                id={boardSectionKey}
                                userId={userId}
                                pipeline={pipeline}
                                stageAppCounts={stageAppCounts}
                                setTotal={setTotal}
                                stageIds={stageIds}
                                selectedUsers={selectedUsers}
                                selectedApplicationByUsers ={selectedApplicationByUsers}
                                apps={boardSections[boardSectionKey]}
                                setBoardSections={setBoardSections}
                                searchQuery={searchQuery}
                                usersList={usersList}
                                
                                loading={loading}
                                toast={toast}
                            />
                        </FlexComponent>
                    ))}
                    <DragOverlay dropAnimation={defaultDropAnimation}>
                        <UserItem app={app} setBoardSections={setBoardSections} toast={toast} />
                    </DragOverlay>
                </FlexComponent>
            </DndContext>
        </div>
    );
};

export default UserSectionList;
