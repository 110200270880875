import React, { useRef, useState, useEffect } from 'react';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import AppItem from './AppItem';
import SortableAppItem from './SortableAppItem';
import { ProgressSpinner } from 'primereact/progressspinner';
import FlexComponent from '../../FlexComponent';
import axios from '../../../utils/axios';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import './apps.css'
import { addStageWatchers, appSubmission, appSubmissionListByStage, getCustomFields, getOpportunitiesListByStage, getStageWatchers, removeStageWatchers, toggleStageWatcher, toggleWatcher } from '../../../utils/urls';
import { InputText } from 'primereact/inputtext';
import Activity from './Activity';
import CheckList from './CheckList';
import AppPopup from './AppPopup';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';


function mergeRefs(...refs) {
  return (element) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    });
  };
}



const AppSection = ({ userId, toast, id, pipeline, apps, usersList, softwareUsers, language, labels, companies, selectedUsers, selectedApplicationByUsers, incomeRangeValue, creditRangeValue, savingsRangeValue, stageIds, stageWatchers, stageAppCounts, setTotal, setBoardSections, searchQuery, loading }) => {
  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id,
  });
  const customRef = useRef(null);
  // const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false)
  const [popupLoad, setPopupLoad] = useState(false)
  const previousOffsetRef = useRef(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [sort, setSort] = useState("DESC")
  const [allStageWatchers, setAllStageWatchers] = useState([])

  const [isOpen, setIsOpen] = useState(false);
  const [applicantData, setApplicantData] = useState()
  const [allCustomFields, setAllCustomFields] = useState([])

  const [stageToggle, setStageToggle] = useState(stageWatchers[id]?.includes(userId))
  const dotsOp = useRef(null);
  const isSuperuser = useSelector((state) => state.user.isSuperuser)

  const getAllCustomFields = () => {
    const listUrl = `${getCustomFields}`
    axios.get(listUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          setAllCustomFields(response.data)
        }
      })
      .catch((error) => {
        // setAppLeads(null)
      })
  }

  const getAllStageWatchers = () => {
    const url = `${getStageWatchers}${stageIds[id]}`
    axios.get(url, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data)
          const options = response?.data?.map((user) => user.user_id) || [];
          // setUsersList(options)
          setAllStageWatchers(options)
        }
      })
      .catch((error) => {
        // setAppLeads(null)
      })
  }

  const handleClick = (formId) => {
    setIsOpen(true);
    setPopupLoad(true)
    getAllCustomFields()
    const url = `${appSubmission}${formId}?pipelineId=${pipeline.id}`
    axios.get(url, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          const data = response.data
          setApplicantData(data)
          setPopupLoad(false)
        }
      })
      .catch((error) => {
        // setAppLeads(null)
      })
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (customRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = customRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1 && !scrollLoading && !isEmpty) {
          const currentOffset = apps.length;

          if (currentOffset === previousOffsetRef.current) {
            return; // No change in offset, so do not make the API call
          }
          let incomeRange = ""
          let creditRange = ""
          let savingsRange = ""
          if (!incomeRangeValue?.includes(parseInt("0")) || !incomeRangeValue?.includes(parseInt("2000000"))) {
            incomeRange = incomeRangeValue
          }
          if (!creditRangeValue?.includes(parseInt("0")) || !creditRangeValue?.includes(parseInt("1000"))) {
            creditRange = creditRangeValue
          }
          if (!savingsRangeValue?.includes(parseInt("0")) || !savingsRangeValue?.includes(parseInt("2000000"))) {
            savingsRange = savingsRangeValue
          }

          setScrollLoading(true);
          const url = `${getOpportunitiesListByStage}?userId=${userId}&stageId=${stageIds[id]}&offset=${currentOffset}&limit=5&search=${searchQuery || ''}&filterUser=${selectedUsers}&applicationByUser=${selectedApplicationByUsers}&language=${language}&labels=${labels}&company=${companies}&income=${incomeRange}&credit=${creditRange}&available=${savingsRange}&sort=${sort}`
          axios.get(url, {
            headers: { 'Content-Type': 'application/json' },
          })
            .then((response) => {
              if (response.status === 200) {
                const dataT = response.data;
                // console.log('dataT', dataT)
                const updatedData = Object.keys(dataT).reduce((acc, key) => ({
                  ...acc,
                  [key]: dataT[key]?.map(item => ({ ...item, id: item.form_id }))
                }), {});

                const convertedArray = Object.values(updatedData).flat();
                if (convertedArray.length === 0) {
                  setIsEmpty(true);
                }
                // console.log('convertedArray', convertedArray)

                if (convertedArray.length !== 0) {
                  setBoardSections(prevState => {
                    const existingApps = prevState[id];
                    const newApps = convertedArray.filter(newApp =>
                      !existingApps?.some(existingApp => existingApp.form_id === newApp.form_id)
                    );
                    return {
                      ...prevState,
                      [id]: [
                        ...existingApps,
                        ...newApps
                      ]
                    };
                  });
                  previousOffsetRef.current = currentOffset; // Update the previous offset
                }

              }
              setScrollLoading(false);
            })
            .catch(() => {
              setScrollLoading(false);
            });
        }
      }
    };

    const currentRef = customRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [id, apps, setBoardSections, scrollLoading, sort, isEmpty]);


  // console.log(id, "id")
  const fetchSortedData = (sortDirection) => {
    let incomeRange = '';
    let creditRange = '';
    let savingsRange = '';

    if (!incomeRangeValue?.includes(parseInt('0')) || !incomeRangeValue?.includes(parseInt('2000000'))) {
      incomeRange = incomeRangeValue;
    }
    if (!creditRangeValue?.includes(parseInt('0')) || !creditRangeValue?.includes(parseInt('1000'))) {
      creditRange = creditRangeValue;
    }
    if (!savingsRangeValue?.includes(parseInt('0')) || !savingsRangeValue?.includes(parseInt('2000000'))) {
      savingsRange = savingsRangeValue;
    }

    // Set loading state to true
    setScrollLoading(true);

    const url = `${getOpportunitiesListByStage}?userId=${userId}&stageId=${stageIds[id]}&offset=0&limit=5&search=${searchQuery || ''}&filterUser=${selectedUsers}&language=${language}&labels=${labels}&company=${companies}&income=${incomeRange}&credit=${creditRange}&available=${savingsRange}&sort=${sortDirection}`;

    axios
      .get(url, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        if (response.status === 200) {
          const dataT = response.data;

          const updatedData = Object.keys(dataT).reduce(
            (acc, key) => ({
              ...acc,
              [key]: dataT[key]?.map((item) => ({ ...item, id: item.form_id })),
            }),
            {}
          );

          const convertedArray = Object.values(updatedData).flat();

          // Replace the existing data with the newly fetched data
          setBoardSections((prevState) => ({
            ...prevState,
            [id]: convertedArray,
          }));
        }
        previousOffsetRef.current = 0
        // Set loading state to false
        setScrollLoading(false);
      })
      .catch(() => {
        setScrollLoading(false);
      });
  };


  const toggleStageWatcherFn = async (stageId) => {
    try {
      const data = {
        stage_id: stageId,
        user_id: userId
      }
      setStageToggle(!stageToggle)
      // console.log(data)
      const response = await axios.post(toggleStageWatcher, data);
      if (response.status === 200) {
        // console.log('Order updated successfully');
        toast.current.show({ severity: 'success', summary: '', detail: 'Success', life: 3000 });

      } else {
        console.error('Failed to update order');
      }
    } catch (error) {
      console.error('Error updating order:', error);
    }
  }

  function getInitials(string) {
    let words = string?.split(' ');
    let initials = words?.map(word => word.charAt(0).toUpperCase());
    return initials?.join('');
}

  const userTemplate = (option) => {
    return (
        <FlexComponent justifyContent="start">
            <Avatar image={option.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(option?.label)}</span>} shape="circle" style={{ marginRight: '8px' }} />
            <span>{option.label}</span>
        </FlexComponent>
    );
};


  const addRemoveStageWatchers = async (value) => {
    try {
      const addedUsers = value.filter(user => !allStageWatchers.includes(user));
      const removedUsers = allStageWatchers.filter(user => !value.includes(user));
  
      setAllStageWatchers(value);
  
      if (addedUsers.length > 0) {
        await axios.post(addStageWatchers, {
          stage_id: stageIds[id],
          user_ids: addedUsers
        });
        toast.current.show({ severity: 'success', summary: '', detail: 'Added successfully', life: 3000 });
      }
  
      if (removedUsers.length > 0) {
        await axios.post(removeStageWatchers, {
          stage_id: stageIds[id],
          user_ids: removedUsers
        });
        toast.current.show({ severity: 'success', summary: '', detail: 'Removed successfully', life: 3000 });
      }
    } catch (error) {
      console.error('Error updating stage watchers:', error);
      toast.current.show({ severity: 'error', summary: '', detail: 'Error updating', life: 3000 });
    }
  };
  

  return (
    <div style={{ borderRadius: '15px', padding: "20px", minWidth: '350px' }} className='applicantCardSectionDiv'>
      <p variant="h6" style={{ color: 'white', marginBottom: '10px', whiteSpace: 'nowrap' }}>
        {/* <FlexComponent  >
          <p>{id}</p>
          <p style={{ fontSize: '18px' }}>{stageAppCounts[id] || 0}</p>
        </FlexComponent> */}
        {loading ? <div className='loadingAppItem' style={{ width: '100%', height: '20px', padding: "0px ", margin: "0px 5px", border: "none" }}>
        </div> :
          <FlexComponent>
            <p>{!loading && id}</p>
            <i style={{ cursor: "pointer" }} className={stageToggle ? 'pi pi-eye' : 'pi pi-eye-slash'} onClick={() => {
              toggleStageWatcherFn(stageIds[id])
            }} ></i>
            <i
              onClick={() => {
                const newSortDirection = sort === 'ASC' ? 'DESC' : 'ASC';
                setSort(newSortDirection);
                // Clear the current apps data
                setBoardSections((prevState) => ({
                  ...prevState,
                  [id]: [],
                }));
                // Call the API with the new sort direction and reset offset to 0
                fetchSortedData(newSortDirection);
              }}
              className={sort === "ASC" ? "pi pi-sort-up" : "pi pi-sort-down"}
              style={{ cursor: "pointer" }}
            >
              {/* {sort === "ASC" ? <i className="pi pi-sort-up" style={{ fontSize: '1.25rem', cursor:"pointer" }}></i> : <i className="pi pi-sort-down" style={{ fontSize: '1.25rem', cursor:"pointer" }}></i>} */}
            </i>
            <p style={{ fontFamily: "DINCondensedC", fontSize: '18px', height: "20px", }}>{!loading && stageAppCounts[id]}</p>
            {isSuperuser && <div onClick={(e) => {
              e.stopPropagation()
              getAllStageWatchers()
              dotsOp.current.toggle(e)
            }}>

              <i style={{ color: "white", cursor: "pointer" }}
                className='pi pi-ellipsis-v'></i>
              <OverlayPanel onClick={(e) => {
                e.stopPropagation()
              }} ref={dotsOp} style={{ background: '#1A2B53' }} >
                <FlexComponent gap="1rem" >
                  <div style={{ minWidth: "260px" }} onClick={(e) => {
                    e.stopPropagation()
                  }}>
                    <div style={{ color: "white", marginBottom: ".4rem" }} >Stage Watchers</div>
                    <MultiSelect
                      // id='value'
                      value={allStageWatchers}
                      options={softwareUsers}
                      style={{ width: '100%', maxWidth: "350px" }}
                      itemTemplate={userTemplate}
                      onChange={(event) => {
                        // setAllStageWatchers(event.value)
                        addRemoveStageWatchers(event.value)
                      }}
                      placeholder='Select'
                      filter
                    />
                  </div>
                </FlexComponent>
              </OverlayPanel>
            </div>}

          </FlexComponent>
        }
      </p>
      <SortableContext
        id={id}
        items={apps}
        strategy={verticalListSortingStrategy}
      >
        <div className='appSection' ref={mergeRefs(setDroppableNodeRef, customRef)} style={{ overflowY: 'scroll', height: '65vh' }}>
          {!loading && apps?.map((app) => (
            <div key={app?.id} style={{ margin: '10px' }} onClick={() => {
              handleClick(app.id)
            }}>
              <SortableAppItem id={app?.id} >
                <AppItem app={app} userId={userId} setBoardSections={setBoardSections} setTotal={setTotal} toast={toast} />
              </SortableAppItem>
            </div>
          ))}

          {loading &&
            (Array(4).fill("").map((item, index) => (
              <div key={index} className='loadingAppItem' style={{ width: '280px', height: '170px' }}>
              </div>
            )))
          }
          {scrollLoading &&
            <FlexComponent justifyContent="center">
              <ProgressSpinner style={{ width: '40px', height: '40px' }} />
            </FlexComponent>
          }
        </div>
      </SortableContext>
      <Dialog className='applicantPopup ' visible={isOpen} onHide={() => handleClose()}>
        <div style={{ marginTop: '1rem', minHeight: "70vh" }}>
          {!popupLoad ? <AppPopup applicantData={applicantData} pipelineId={pipeline.id} allCustomFields={allCustomFields} usersList={usersList} userId={userId} setBoardSections={setBoardSections} handleClick={handleClick} handleClose={handleClose} toast={toast} /> :
            <FlexComponent flexDirection="column" justifyContent="center" >
              <div style={{ minWidth: "40vw", height: "100%" }} className='loadingAppItem' ></div>
            </FlexComponent>
          }
        </div>
      </Dialog>
    </div>
  );
};

export default AppSection;
