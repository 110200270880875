import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { AppUserList, getReportApps, getReportLeads, updateLeadAssignedUser } from '../../utils/urls';
import axios from '../../utils/axios';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import FlexComponent from '../FlexComponent';
import { Avatar } from 'primereact/avatar';

export default function RepliesTable({ userId, countsIds = false }) {
    const navigate = useNavigate()
    const [appLeads, setAppLeads] = useState(null);
    const [usersList, setUsersList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const dt = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        if (userId) {
            const payloadData = {
                lead_ids : countsIds
            }
            axios.post(`${getReportLeads}`, payloadData, {
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => {
                    if (response.status === 200) {
                        // console.log(response.data)
                        setAppLeads(response.data.leads);
                        setUsersList(response.data.users)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setAppLeads(null)

                })
        }
    }, [isMounted, userId])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        location_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        app_application_by: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        app_status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        language: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div className='status'>{rowIndex}</div>;
    };


    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className='header-container-with-csv-export'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
                <div className="flex align-items-center justify-content-end gap-2">
                    <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                </div>
            </div>

        );
    };


    const handleAddAssignedUser = async (value, contactId) => {
        try {
            setIsMounted(true)
            let data = {
                user: {
                    id: value
                }
            }
            // console.log(data)
            let url = `${updateLeadAssignedUser}${contactId}`
            // console.log(url);
            
            await axios.post(url, data, {
                headers: { 'Content-Type': 'application/json' },
            });
            setIsMounted(false)
            toast.current.show({ severity: 'success', summary: '', detail: 'Added successfully', life: 3000 });

        } catch (error) {
            //   console.error('Error updating stage watchers:', error);
            setIsMounted(false)
            toast.current.show({ severity: 'error', summary: '', detail: 'Error updating', life: 3000 });
        }
    };

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

      const userTemplate = (option) => {
        return (
            <FlexComponent justifyContent="start">
                <Avatar image={option.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(option?.label)}</span>} shape="circle" style={{ marginRight: '8px' }} />
                <span>{option.label}</span>
            </FlexComponent>
        );
    };

    const assigneduserBody = (rowData) => {
        const assignedUserId = rowData.assigned_user_id
        const locationId = rowData.location_id
        const contactId = rowData.ghl_contact_id

        const filteredUsers = usersList
            .filter(user => user.location_ids.includes(locationId)) // Ensure user has the same locationId
            .map(user => ({
                label: user.name,
                value: user.id,
                image : user.image
            }));
        // console.log(filteredUsers.length)

        return <div>
            <Dropdown
                id="designationId"
                // value={getIdByCompanyName(company)}
                value={assignedUserId}
                options={filteredUsers}
                style={{ width: '100%' }}
                onChange={(e) => {
                    handleAddAssignedUser(e.value, contactId)
                }}
                filter
                itemTemplate={userTemplate}
                placeholder="Select User"
            />
        </div>
    };

    const profileView = (rowData) => {
        const contact_id = rowData.ghl_contact_id
        const location_id = rowData.location_id
        return <Button className='view-button' label="View" icon="pi pi-external-link" onClick={() => window.open(`https://phone.bcrumbs.com/v2/location/${location_id}/contacts/detail/${contact_id}`, '_blank')} />
    };


    const header = renderHeader();

    return (
        <div className="card p-fluid">
            <Toast ref={toast} />
            <DataTable ref={dt} value={appLeads} paginator rows={15} rowsPerPageOptions={[15, 30, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)} dataKey="ghl_contact_id"
                stateStorage="session" stateKey="dt-apps-table" tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ minWidth: '1rem' }} body={srNoBodyTemplate} />
                <Column field="full_name" header="Name" style={{ minWidth: '13rem' }} sortable ></Column>
                <Column field="email" header="Email" style={{ minWidth: '2rem' }} sortable ></Column>
                <Column field="phone" header="Phone" style={{ minWidth: '2rem' }} sortable ></Column>
                <Column field="location_name" header="Location" style={{ minWidth: '13rem' }} sortable ></Column>
                <Column field="language" header="Language" style={{ minWidth: '8rem' }} sortable ></Column>
                <Column field="live_assignment" header="Live Assignment" style={{ minWidth: '13rem' }} sortable ></Column>
                <Column field="campaign" header="Campaign" style={{ minWidth: '13rem' }} sortable ></Column>
                <Column field="replied_date" header="Replied Date" style={{ minWidth: '10rem' }} sortable></Column>
                <Column field="date_created" header="Date" style={{ minWidth: '10rem' }} sortable></Column>
                <Column body={assigneduserBody} header="Assgined User" style={{ minWidth: '10rem' }} sortable></Column>
                <Column body={profileView} style={{ minWidth: '1rem' }}></Column>
            </DataTable>
        </div>
    );
}