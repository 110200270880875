import React, {useState, useEffect, useRef} from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { AppUserList, getReportApps } from '../../utils/urls';   
import axios from '../../utils/axios'; 
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export default function AppsTable({userId, countsIds = false}) {
    const navigate = useNavigate()
    const [appLeads, setAppLeads] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const dt = useRef(null);

    useEffect(()=>{
        if(userId){
            if(!countsIds){
                axios.get(`${AppUserList}?userId=${userId}`, {
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setAppLeads(response.data);
                        setLoading(false);
                        }
                })
                .catch((error)=>{
                    setAppLeads(null)
                    
                })
            }else{
                axios.get(`${getReportApps}?app_id=${countsIds}`, {
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setAppLeads(response.data);
                        setLoading(false);
                        }
                })
                .catch((error)=>{
                    setAppLeads(null)
                    
                })
            }
        }
    },[AppUserList, isMounted, userId])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        location_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        app_application_by: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        app_status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        language: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
   
    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div className='status'>{rowIndex}</div>;
      };


      const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };
    
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
                <div className='header-container-with-csv-export'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
                <div className="flex align-items-center justify-content-end gap-2">
                    <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                </div>
                </div>
                
        );
    };

    const profileView = (rowData) => {
        const contact_id = rowData.ghl_contact_id
        const form_id = rowData.form_id
        return <Button className='view-button' label="View" icon="pi pi-external-link" onClick={()=>redirectProfilePage(contact_id, form_id)} />
    };

    const redirectProfilePage = (contact_id, form_id) =>{
        navigate(`/app/${contact_id}/${form_id}`)
    }
    
    const header = renderHeader();

    return (
        <div className="card p-fluid">
            <DataTable ref={dt} value={appLeads} paginator rows={15} rowsPerPageOptions={[15, 30, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)} dataKey="ghl_contact_id" 
                    stateStorage="session" stateKey="dt-apps-table" tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ minWidth: '1rem'  }} body={srNoBodyTemplate} />
                <Column field="full_name" header="Name" style={{ minWidth: '13rem' }} sortable ></Column>
                <Column field="email" header="Email" style={{ minWidth: '2rem' }} sortable ></Column>
                <Column field="phone" header="Phone" style={{ minWidth: '2rem' }} sortable ></Column>
                <Column field="location_name" header="Location" style={{ minWidth: '13rem' }} sortable ></Column>
                <Column field="language" header="Language" style={{ minWidth: '2rem' }} sortable ></Column>
                <Column field="app_application_by" header="Application By" style={{ minWidth: '11rem' }} sortable></Column>
                <Column field="app_status" header="Status" style={{ minWidth: '13rem' }} sortable></Column>
                <Column field="income" header="Income" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="credit" header="Credit" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="savings" header="Savings" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="created_date" header="Date" style={{ minWidth: '10rem' }} sortable></Column>
                <Column body={profileView} style={{ minWidth: '1rem' }}></Column>
            </DataTable>
        </div>
    );
}