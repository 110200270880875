import { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ToggleButton } from 'primereact/togglebutton';
import { getGhlUsers, deleteLeader, leaderLocations, leaderLocationManage, passwordUpdate, updateUserDesignation, updateUserCompany, getSoftwareUsers, convertToUser, convertToLeader, terminateSoftwareUser, updateOfficeEmailPassword, leaderTeams, leaderTeamManage } from '../../utils/urls';   
import axios from '../../utils/axios';       
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import FlexComponent from '../FlexComponent';

export default function OnboardedUsersTable({designationList, companiesList}) {
    const navigate = useNavigate()
    const toast = useRef(null);
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        username: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const [locationVisible, setLocationVisible] = useState(false);
    const [allowedLocations, setAllowedLocations] = useState(null);
    const [activeLocations, setActiveLocations] = useState(null);
    const [manageLeaderId, setManageLeaderId] = useState(null);
    const [convertLeader, setConverLeader] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [userVisible, setUserVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [selectedPasswordUser, setSelectedPasswordUser] = useState(null);
    // const [desigantionId, setDesignationId] = useState('')
    const [password360Visible, setPassword360Visible] = useState(false);
    const [emailPassword360Visible, setEmailPassword360Visible] = useState(false);
    const [email360, setEmail360] = useState('');
    const [password360, setPassword360] = useState('');
    const [officeTitle, setOfficeTitle] = useState('');
    const [selectedEmailPassword360User, setSelectedEmailPassword360User] = useState(null);
    const dt = useRef(null);

    useEffect(()=>{
        // setLoading(true);
        getAllSoftwareUsers()
    },[getSoftwareUsers, isMounted])

    const getAllSoftwareUsers = ()=> {
        axios.get(getSoftwareUsers, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(response.data);
                    setLoading(false);
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    }

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const getSeverity = (value) => {
        switch (value) {
            case true:
                return 'success';

            case false:
                return 'danger';

            default:
                return null;
        }
    };

    const manageLocationView = (rowData) => {
        const leader_id = rowData.id
        return <div>
            {rowData.user_type === "Team Leader" ? <Button className='view-button' label="Manage" icon="pi pi-external-link" onClick={()=>manageTeamDialog(leader_id)} />
            : <Button className='view-button' label="Manage" icon="pi pi-external-link" onClick={()=>manageLocationDialog(leader_id)} />
            }
            </div>
        
    };

    const manageUserTypes = (rowData) => {
        const user_id = rowData.id
        // return <Button className='view-button' label="Manage" icon="pi pi-external-link" onClick={()=>manageLocationDialog(leader_id)} />
        return <div>
            {rowData.user_type === "User" ? <Button className='view-button' label="Convert To Leader"  onClick={()=>manageTeamDialog(user_id, true)} /> : 
                <Button className='view-button' label="Convert To User"  onClick={()=>convertUserLeader(rowData.user_type, user_id)} /> }
        </div>
    };

    const convertUserLeader = (userType, userId)=> {
        confirmDialog({
            message: userType === "User" ? 'Are you sure you want to Convert this User To Team Leader' : "Are you sure you want to Convert this Team Leader To User",
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setIsMounted(true);
                
                let Url = ""
                if(userType === "User"){
                    Url = `${convertToLeader}${userId}`;
                }else if(userType === "Team Leader" ){
                    Url = `${convertToUser}${userId}`;
                }

                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsMounted(false);
                            toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                            // getPipelinesList();
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            setIsMounted(false);
                        }
                    })
                    .catch(() => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        setIsMounted(false);
                    });
            },
            reject: ()=> {
                console.log("closed")
            }
        })
    }

    function getIdByName(name) {
        const item = designationList.find(obj => obj.title === name);
        return item ? item.id : null; // Return the name if found, otherwise return null
    }

    const designationView = (rowData) => {
        const userId = rowData.id
        const designation = rowData.designation
        return  <div>

            <Dropdown
                id="designationId"
                value={getIdByName(designation)}
                options={designationList.map(designation => ({
                    label: designation.title,
                    value: designation.id,
                    })) || []}
                style={{ width: '100%' }}
                onChange={(e) => {
                    // setPipelineId(e.value)
                    handleAddDesignation(e.value, userId)
                }}
                placeholder="Select Designation"
                />
        </div> 
    };

    function getIdByCompanyName(name) {
        const item = companiesList.find(obj => obj.title === name);
        return item ? item.id : null; // Return the name if found, otherwise return null
    }

    const companyView = (rowData) => {
        const userId = rowData.id
        const company = rowData.company
        return  <div>
            <Dropdown
                id="designationId"
                value={getIdByCompanyName(company)}
                options={companiesList.map(company => ({
                    label: company.title,
                    value: company.id,
                    })) || []}
                style={{ width: '100%' }}
                onChange={(e) => {
                    // setPipelineId(e.value)
                    handleAddCompany(e.value, userId)
                }}
                placeholder="Select Company"
                />
        </div> 
    };
    
    const manageLocationDialog = (leader_id, userType = false) =>{
        const manageLocationUrl = `${leaderLocations}${leader_id}`

        axios.get(manageLocationUrl, {
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setActiveLocations(response.data.all_active_locations);
                    setAllowedLocations(response.data.allowed_locations);
                    setManageLeaderId(leader_id)
                    setLocationVisible(true)
                  }
                })
            .catch((error)=>{
                setActiveLocations(null)
                setAllowedLocations(null)
                setManageLeaderId(null)
                setLocationVisible(false)
                
            })
    }

    const manageTeamDialog = (leader_id) =>{
        const manageLocationUrl = `${leaderTeams}${leader_id}`

        axios.get(manageLocationUrl, {
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setActiveLocations(response.data.all_active_teams);
                    setAllowedLocations(response.data.allowed_teams);
                    setManageLeaderId(leader_id)
                    setConverLeader(true)
                    setLocationVisible(true)
                  }
                })
            .catch((error)=>{
                setActiveLocations(null)
                setAllowedLocations(null)
                setManageLeaderId(null)
                setConverLeader(false)
                setLocationVisible(false)
                
            })
    }

    const accept = (Data) => {
        axios.post(deleteLeader, Data,{
        headers: {'Content-Type': 'application/json'},
        })
        .then((response) => {
            if (response.status === 200) {
                setIsMounted(!isMounted);
                toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            else{
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });   
            }
            })
        .catch((error)=>{
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });   
        })
    }

    const reject = () => {
        console.log('closed')
    }

    const deleteBody = (rowData) => {
        const leader_id = rowData.id
        return <Button icon="pi pi-trash" severity="danger" aria-label="Delete" onClick={()=>handleDeleteLeader(leader_id)}/>;
    };

    const terminateBody = (rowData) => {
        const user_id = rowData.id
        return <div>
        {rowData.is_terminated ? <Button className='view-button' label="Terminated" onClick={()=>TerminateUser(rowData.is_terminated, user_id)} />  : 
             <Button style={{background: "green"}} className='view-button' label="Active" onClick={()=>TerminateUser(rowData.is_terminated, user_id)} /> }
    </div>
    };

    const TerminateUser = (isActive, userId)=> {
        confirmDialog({
            message: isActive ? "Are you sure you want to Activate this User" : 'Are you sure you want to Terminate this User' ,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                
                let Url = `${terminateSoftwareUser}${userId}`;
                setIsMounted(true)

                let statusString = !isActive

                const data = {
                    status : String(statusString)
                }
                // console.log(data)

                axios.post(Url, data, {
                    headers: { 'Content-Type': 'application/json' }
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsMounted(false);
                            toast.current.show({ severity: 'success', summary: 'User Terminated Successfully', detail: '', life: 3000 });
                            // getPipelinesList();
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            setIsMounted(false);
                        }
                    })
                    .catch(() => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        setIsMounted(false);
                    });
            },
            reject: ()=> {
                console.log("closed")
            }
        })
    }

    const onUpload = () => {
        setIsMounted(!isMounted)
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'Profile image updated' });
    };

    const uploadBody = (rowData) => {
        const leader_id = rowData.id
        return <FileUpload mode="basic" name="image" url={`https://backendapi.homesforsalemedia.com/api/upload/${leader_id}`} accept="image/*" maxFileSize={10485760} onUpload={onUpload} auto chooseLabel="Upload" />;
    };

    const handlePasswordOn = (user_id) =>{
        setUserVisible(true)
        setSelectedPasswordUser(user_id)
    }

    const handlePasswordOnHide = () =>{
        setUserVisible(false)
        setSelectedPasswordUser(null)
        setPassword('')
    }

    const handleEmailPassword360On = (rowData) =>{
        setEmailPassword360Visible(true)
        setSelectedEmailPassword360User(rowData.id)
        setEmail360(rowData.office_360_email)
        setPassword360(rowData.office_360_password)
        setOfficeTitle(rowData.title)
    }

    const handleEmailPassword360OnHide = () =>{
        setEmailPassword360Visible(false)
        setSelectedEmailPassword360User(null)
        setOfficeTitle('')
        setEmail360('')
        setPassword360('')
        setPassword360Visible(false)
    }

    const updatePasswordBody = (rowData) => {
        const leader_id = rowData.id
        return <Button className='view-button' label="" icon="pi pi-user-edit" onClick={()=>handlePasswordOn(leader_id)} />
    };

    const update360EmailPassword = (rowData) => {
        const leader_id = rowData.id
        return <Button className='view-button' label="Office 360" icon="pi pi-user-edit" onClick={()=>handleEmailPassword360On(rowData)} />
    };

    const handlePasswordVisible = () =>{
        setPasswordVisible(!passwordVisible)
    }

    const handlePassword360Visible = () =>{
        setPassword360Visible(!password360Visible)
    }

    function isPasswordValid(password) {
        // Regular expressions for each rule
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const digitRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
      
        // Check if the password meets all the rules
        const isLengthValid = password.length >= 8;
        const hasUppercase = uppercaseRegex.test(password);
        const hasLowercase = lowercaseRegex.test(password);
        const hasDigit = digitRegex.test(password);
        const hasSpecialChar = specialCharRegex.test(password);
      
        // Return true if all rules are met, otherwise false
        return isLengthValid && hasUppercase && hasLowercase && hasDigit && hasSpecialChar;
    }


    const handlePasswordSubmit = () =>{
        // e.preventDefault();

        const trimmedPassword = password ? password.trim() : null;

        if (trimmedPassword == null || trimmedPassword === '') {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter the password', life: 2000});
        }
        else{

            const isValid = isPasswordValid(password);

            if (isValid) {
                const data = {
                    password,
                };

                axios.post(`${passwordUpdate}${selectedPasswordUser}`, data,{
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response) => {
                    if (response.status === 200) {
                        setIsMounted(!isMounted)
                        setUserVisible(false)
                        setSelectedPasswordUser(null)
                        setPassword('')
                        toast.current.show({severity:'success', summary: 'Success', detail:'', life: 3000});                
                    }
                    else{
                        toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});          
                    }
                })
                .catch((error)=>{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});          
                })
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'The password must be more than 8 characters including uppercase, lowercase, numbers and special characters', life: 3000});
            }
        }
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailPassword360Submit = () =>{
        // e.preventDefault();

        // const trimmedPassword = password360 ? password360.trim() : null;

        if ( !email360.trim()) {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter the Email', life: 2000});
        }
        else{

            const isValid = isValidEmail(email360);

            if (isValid) {

                const data = {
                    title : officeTitle,
                    email : email360, 
                    password : password360,
                };

                axios.post(`${updateOfficeEmailPassword}${selectedEmailPassword360User}`, data,{
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response) => {
                    if (response.status === 200) {
                        setIsMounted(!isMounted)
                        setEmailPassword360Visible(false)
                        setSelectedEmailPassword360User(null)
                        // setPassword360('')
                        toast.current.show({severity:'success', summary: 'Success', detail:'', life: 3000});                
                    }
                    else{
                        toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});          
                    }
                })
                .catch((error)=>{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});          
                })
            }else{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Please Enter Valid Email', life: 3000});
                }
            
        }
    }

    const userManageFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handlePasswordSubmit} autoFocus />
        </div>
    );

    const emailPassword360Footer = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleEmailPassword360Submit} autoFocus />
        </div>
    );

    const handleDeleteLeader = (leader_id) => {
        const data = {
            leader_id
        }
        
        confirmDialog({
            message: 'Are you sure you want to delete this User?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(data),
            reject
        });
    }

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className='header-container-with-csv-export'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
                <div className="flex align-items-center justify-content-end gap-2">
                    <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                </div>
                 {/* <Button className='sync-button' label="Sync" icon="pi pi-sync" onClick={syncHandle} /> */}
             </div>
        );
    };

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };

      const handleLocationOnHide = () =>{
        setActiveLocations(null)
        setAllowedLocations(null)
        setManageLeaderId(null)
        setConverLeader(false)
        setLocationVisible(false)
    }

    const handleLocationSubmit = () =>{

        let Url = ""
        let data = {}
        if(convertLeader){
            Url = `${convertToLeader}${manageLeaderId}`
            data = {
                "allowedTeams": allowedLocations?.map((team)=> team.team_id),
            }
        }else{
            Url = leaderLocationManage
            data = {
                "leader_id": manageLeaderId,
                "allowed_locations": allowedLocations
            }
        }
         
        axios.post(Url, data,{
            headers: {'Content-Type': 'application/json'},
        })
        .then((response) => {
            if (response.status === 200) {
                setActiveLocations(null)
                setAllowedLocations(null)
                setManageLeaderId(null)
                setConverLeader(false)
                setLocationVisible(false)
                setIsMounted(!isMounted)
                toast.current.show({severity:'success', summary: 'Success', detail:'', life: 3000});                
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});          
        })
    }

    const locationManageFooter = (
        <div>
            <Button className='view-button' disabled={(convertLeader && !allowedLocations.length ? true : false)} label="Submit" icon="pi pi-check" onClick={handleLocationSubmit} autoFocus />
        </div>
    );

    const getInitials = (name) => {
        const words = name.split(' ');
        const firstInitial = words[0] ? words[0][0].toUpperCase() : '';
        const secondInitial = words[1] ? words[1][0].toUpperCase() : '';
        return firstInitial + secondInitial;
      };

    const userBodyTemplate = (rowData) => {
        const image_url = rowData.image;
        const initials = getInitials(rowData.full_name);

        return (
            <div className="flex align-items-center gap-2" style={{display:'flex', alignItems:'center', gap:'1rem'}}>
                {image_url?
                    <img src={image_url} width="32" />
                    :
                    <Avatar label={initials} style={{ backgroundColor: '#0f173e', color: '#ffffff' }} shape="circle" />
                }
                <span>{rowData.full_name}</span>
            </div>
        );
    };



    const handleAddDesignation = (designationId, userId) => {
        setIsMounted(true)
        // setDesignationId(designationId)
        const data = {
            designation_id: designationId
        }
        const url = `${updateUserDesignation}${userId}`
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    setIsMounted(false)
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setIsMounted(false)

            })
    }

    const handleAddCompany = (companyId, userId) => {
        setIsMounted(true)
        const data = {
            company_id: companyId
        }
        const url = `${updateUserCompany}${userId}`
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    setIsMounted(false)
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setIsMounted(false)

            })
    }

    const header = renderHeader();

    return (
        <div className="card">
            <Toast ref={toast} />
            <ConfirmDialog />

            <DataTable ref={dt} value={customers} paginator rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)}
                    dataKey="id"
                    stateStorage="session" stateKey="dt-state-demo-local" emptyMessage="No users found." tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ minWidth: '2rem'  }} body={srNoBodyTemplate} />
                <Column header="Name" field='full_name' body={userBodyTemplate} sortable style={{ minWidth: '2rem' }}></Column>
                <Column field="email" header="Email" sortable style={{ minWidth: '2rem' }}></Column>
                <Column field="username" header="User Name" sortable style={{ minWidth: '2rem' }}></Column>
                <Column field="user_type" header="User Role" sortable style={{ minWidth: '8rem' }}></Column>
                <Column field="location_count" header="Locations/ Teams" sortable style={{ minWidth: '2rem' }}></Column>
                {/* <Column header="Status" body={statusView} style={{minWidth: '2rem' }}></Column> */}
                <Column header="Manage Locations/Teams" body={manageLocationView} style={{ minWidth: '2rem' }}></Column>
                <Column header="Manage User Type" body={manageUserTypes} style={{ minWidth: '14rem' }}></Column>
                <Column header="Designation" body={designationView} style={{ minWidth: '2rem' }}></Column>
                <Column header="Company" body={companyView} style={{ minWidth: '2rem' }}></Column>
                <Column header="Profile Image" body={uploadBody} style={{ width: '15%' }}></Column>
                <Column header="Update Password" body={updatePasswordBody} style={{ minWidth: '2rem' }}></Column>
                <Column header="Update 360 Email/Password" body={update360EmailPassword} style={{ minWidth: '11rem' }}></Column>
                <Column header="Status" body={terminateBody} style={{ minWidth: '2rem' }}></Column>
                <Column body={deleteBody} style={{ minWidth: '2rem' }}></Column>
            </DataTable>

            <Dialog header={convertLeader ? "Add Atleast One Team" : "Manage Allowed Locations"} visible={locationVisible} style={{ width: '50vw' }} onHide={handleLocationOnHide} footer={locationManageFooter}>
            <div>
                <div className='purchase-form-group'>
                <div className="card flex justify-content-center">
                <MultiSelect value={allowedLocations} onChange={(e) => setAllowedLocations(e.value)} options={activeLocations} optionLabel="name" 
                    filter placeholder={convertLeader ? "Add Team" : "Manage Locations"} maxSelectedLabels={3} className="w-full md:w-20rem language-multiselect" />
                </div>
                </div>
            </div>
            </Dialog>

            <Dialog header="Set New Password" visible={userVisible} style={{ width: '50vw' }} onHide={handlePasswordOnHide} footer={userManageFooter}>
            <div>
            <div className='purchase-form-group'>
                    {/* <div className="card flex justify-content-center"> */}
                        <form className='user-form'>
                            
                            <div className="flex justify-content-center">
                                
                                <div className="flex flex-column gap-2 lable-input-group" style={{width:'100%'}}>
                                    <label htmlFor="password">New Password *</label>
                                    <span className="p-input-icon-right full-input">
                                        <i className={`pi ${passwordVisible ? 'pi-eye' : 'pi-eye-slash'} password-icon`} onClick={handlePasswordVisible}/>
                                        <InputText value={password}  type={`${passwordVisible ? 'text' : 'password'}`} className='full-input' id="password" aria-describedby="password-help" onChange={(e) => setPassword(e.target.value)}/>
                                    </span>
                                </div>
                            </div>
                        </form>
                    {/* </div> */}
                </div>
            </div>
            </Dialog>

            <Dialog header="Update Office 360 Email and Password" visible={emailPassword360Visible} style={{ width: '50vw' }} onHide={handleEmailPassword360OnHide} footer={emailPassword360Footer}>
            <div>
            {/* <div className='purchase-form-group' style={{textAlign: "center"}}> */}
                    {/* <div className="card flex justify-content-center"> */}
                        {/* <form className='user-form'> */}
                            <FlexComponent gap="1rem" className='profileContainer' >
                            {/* <div className="flex justify-content-center"> */}
                            <div className="flex flex-column gap-2 lable-input-group" style={{width:'100%'}}>
                                    <label htmlFor="password">Title </label>
                                    <span className="p-input-icon-right full-input">
                                        <InputText value={officeTitle} className='full-input' id="officeTitle"  onChange={(e) => setOfficeTitle(e.target.value)}/>
                                    </span>
                                </div>
                                <div className="flex flex-column gap-2 lable-input-group" style={{width:'100%'}}>
                                    <label htmlFor="password">Email </label>
                                    <span className="p-input-icon-right full-input">
                                        <InputText value={email360} className='full-input' id="email360"  onChange={(e) => setEmail360(e.target.value)}/>
                                    </span>
                                </div>
                                <div className="flex flex-column gap-2 lable-input-group" style={{width:'100%'}}>
                                    <label htmlFor="password">Password </label>
                                    <span className="p-input-icon-right full-input">
                                        <i className={`pi ${password360Visible ? 'pi-eye' : 'pi-eye-slash'} password-icon`} onClick={handlePassword360Visible}/>
                                        <InputText value={password360}  type={`${password360Visible ? 'text' : 'password'}`} className='full-input' id="password" aria-describedby="password-help" onChange={(e) => setPassword360(e.target.value)}/>
                                    </span>
                                </div>
                            {/* </div> */}
                            </FlexComponent>
                        {/* </form> */}
                    {/* </div> */}
                {/* </div> */}
            </div>
            </Dialog>
        </div>
    );
}