import React, { useState } from "react";
import { createRoot } from "react-dom";
import { useNavigate } from "react-router-dom";
import  BubblyBubbles  from "./BubblyBubbles";

const BubblyLink = ({
  to,
  children,
  colorStart = "#8f44fd",
  colorEnd = "#ffffff",
  duration = 0,
}) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(to);
  };

  return (
    <button
    style={{height: "50px", minWidth: "40px"}}
      type="button"
      className={`react-bubbly-transitions__bubbly-link ${
        window.location.pathname === to ? "active" : ""
      }`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default BubblyLink;
