import React, { useEffect, useState, useRef } from 'react'
import './profileView.css'
import FlexComponent from '../../components/FlexComponent'
import { Nav } from '../../shared/Nav'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom/dist'
import axios from '../../utils/axios'
import { verifyToken, getAppLead, UpdateAppStage, AddFollowers, AddSales, MoveToFollower, MoveToSales, RemoveFollowerSales, updateAssignedUser, auditLog, UpdateLeadStage, appKanbanUserList } from '../../utils/urls'
import jwt_decode from "jwt-decode";
import { setUser } from '../../utils/userSlice'
import Swal from 'sweetalert2'
import { RefreshToken } from '../../utils/refreshToken'
import { Avatar } from 'primereact/avatar'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import PersonalInformation from '../../components/profileView/PersonalInformation'
import Applicant from '../../components/profileView/Applicant'
import WorkExperience from '../../components/profileView/WorkExperience'
import Address from '../../components/profileView/Address'
import ShoppingFor from '../../components/profileView/ShoppingFor'
import TypeOfHome from '../../components/profileView/TypeOfHome'
import SavingsAndAssets from '../../components/profileView/SavingsAndAssets'
import PropertyOwned from '../../components/profileView/PropertyOwned'
import { Toast } from 'primereact/toast';
import FormSubmissionList from '../../components/profileView/FormSubmissionList'
import CoSigner from '../../components/profileView/CoSigner'
import AuditLogs from '../../components/profileView/AuditLogs'
import Activity from '../../components/apps/appsKanban/Activity'
// import Activity from '../../components/profileView/Activity'
import adminLogo from '../../Images/admin_img.jpg'


function ProfileView() {

    const toast = useRef(null);
    const navigate = useNavigate()
    const location = useLocation();
    const dispatch = useDispatch();
    // const { isLoggedIn, setIsLoggedIn } = useContext(UserIsLoggedIn);
    const { contactId, formId } = useParams();
    const url = `${getAppLead}${contactId}/${formId}`
    const isSuperuser = useSelector((state) => state.user.isSuperuser);
    const image = useSelector((state) => state.user.image);
    const userLable = useSelector((state) => state.user.userLable);
    const userName = useSelector((state) => state.user.userName);
    const is_leader = useSelector((state) => state.user.is_leader);
    const userId = useSelector((state) => state.user.userId);
    const [scrolling, setScrolling] = useState(false);
    const [profileData, setProfileData] = useState(null)
    const [applicantData, setApplicantData] = useState(null);
    const [usersList, setUsersList] = useState([])
    const [workData, setWorkData] = useState(null);
    const [addressData, setAddressData] = useState(null);
    const [cosignerData, setCosignerData] = useState(null);
    const [shoppingData, setShoppingData] = useState(null);
    const [typeOfHomeData, setTypeOfHomeData] = useState(null);
    const [savingsData, setSavingsData] = useState(null);
    const [propertyData, setPropertyData] = useState(null);
    const [notes, setNotes] = useState(null);
    const [formList, setFormList] = useState(null);
    const [isMounted, setIsMounted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [memberLoading, setMemberLoading] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const [auditLogs, setAuditLogs] = useState()
    // const formListDataArr = [
    //     {
    //         "form_id": 1,
    //         "user_id": "bzGbsRIwtwtaCkkLnTfI",
    //         "user_name": "User One",
    //         "date": "2024-04-21T22:29:53.162000Z"

    //     },
    //     {
    //         "form_id": 2,
    //         "user_id": "6Yd09cclWUyw8dGEDI8h",
    //         "user_name": "User Two",
    //         "date": "2024-04-21T07:15:34.837000Z"

    //     },
    //     {
    //         "form_id": 3,
    //         "user_id": "QmxXdwEXb4k0seuLSvda",
    //         "user_name": "User Three",
    //         "date": "2024-04-19T13:39:27.514000Z"
    //     }
    // ]

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const authTokens = JSON.parse(localStorage.getItem('authTokens'))
        const refresh = authTokens?.refresh
        if (refresh) {
            const body = JSON.stringify({
                token: refresh,
            });
            axios
                .post(verifyToken, body, {
                    headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                    if (response.status === 200) {
                        const access = authTokens?.access
                        const decodedToken = jwt_decode(access)
                        dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser, userLable: decodedToken.userLable, image: decodedToken.image, userName: decodedToken.userName, ghlUserId: decodedToken.ghlUserId, is_leader: decodedToken.is_leader }));
                        localStorage.setItem("prevUrl", window.location.pathname);
                        // const superUser = decodedToken.is_superuser
                        // if (superUser) {
                        //     dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser }));
                        //     localStorage.setItem("prevUrl", window.location.pathname);
                        // }
                        // else {
                        //     navigate('/')
                        // }
                    }
                })
                .catch((err) => {
                    localStorage.removeItem('authTokens');
                    localStorage.removeItem('prevUrl');
                    dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
                    // dispatch(change({ id: null ,username: null, image: null }));
                    // setIsLoggedIn(false)
                    navigate('/login')
                });
        } else {
            localStorage.removeItem('authTokens');
            localStorage.removeItem('prevUrl');
            dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
            // dispatch(change({ id: null ,username: null, image: null }));
            // setIsLoggedIn(false)
            navigate('/login')
        }
    }, [isSuperuser])

    useEffect(() => {
        const intervalId = setInterval(async () => {
            await RefreshToken();
        }, 3600000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure to log out?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('authTokens');
                localStorage.removeItem('prevUrl');
                // dispatch(change({ username: null, image: null }));
                navigate('/login')
            }
        })
    }

    const auditLogsFn = async () => {
        const auditUrl = `${auditLog}${formId}`
        axios.get(auditUrl, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data.audit_log)
                    setAuditLogs(response.data.audit_log)
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                // setLoading(false)
            })
    }

    const loadUsersData = ()=>{
        if(userId){
        const url = `${appKanbanUserList}?userId=${userId}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const options = response?.data?.map((user) => ({
                        label: user.name,
                        value: user.user_id,
                        image: user.image
                    })) || [];
                    setUsersList(options)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })
        }
    }

    useEffect(() => {
        // setLoading(true);
        auditLogsFn()
        loadUsersData()
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setProfileData(response.data.personal_data)
                    setApplicantData(response.data.applicant_data)
                    setWorkData(response.data.work_experience_data)
                    setAddressData(response.data.address_data)
                    setShoppingData(response.data.shopping_data)
                    setTypeOfHomeData(response.data.type_of_home_data)
                    setSavingsData(response.data.savings_assets_data)
                    setPropertyData(response.data.property_owned_data)
                    setFormList(response.data.form_list)
                    setCosignerData(response.data.cosigner_list)
                    setNotes(response.data.notes)
                }
                setLoading(false)
                setIsSubmit(false)
            })
            .catch((error) => {
                setProfileData(null)
                setApplicantData(null)
                setWorkData(null)
                setAddressData(null)
                setShoppingData(null)
                setTypeOfHomeData(null)
                setSavingsData(null)
                setPropertyData(null)
                setFormList(null)
                setCosignerData(null)
                setIsSubmit(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                // setLoading(false)
            })
    }, [url, isMounted, isSubmit, userId])

    const handleStageUpdate = (contact_id, form_id, stage, stageId) => {
        const data = {
            stage_id: stageId,
            userId: userId
        }
        const stageUrl = `${UpdateLeadStage}${contact_id}/${form_id}`
        axios.post(stageUrl, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                }
                else if (response.status === 208) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }

    const AddLeadFollowers = (selectedUsers) => {
        const data = { 
            selectedUsers,
            userId : userId,
            app_id : formId
         }
        const Url = `${AddFollowers}${contactId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }



    const AddLeadSales = (selectedUsers) => {
        const data = { 
            selectedUsers,
            userId : userId,
            app_id : formId
         }
        const Url = `${AddSales}${contactId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }

    const MoveLeadSalesToFollower = (user) => {
        const data = { 
            user,
            userId : userId,
            app_id : formId
         }
        const Url = `${MoveToFollower}${contactId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }

    const MoveLeadFollowerToSales = (user) => {
        const data = { 
            user,
            userId : userId,
            app_id : formId
         }
        const Url = `${MoveToSales}${contactId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }

    const RemoveLeadFollowerSales = (user) => {
        const data = { 
            user,
            userId : userId,
            app_id : formId
         }
        const Url = `${RemoveFollowerSales}${contactId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }

    const updateContactOpportunityOwner = (user) => {
        console.log(user)
        setMemberLoading(true)
        const data = { 
            user,
            userId : userId,
            app_id : formId
         }
        const Url = `${updateAssignedUser}${contactId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                    setMemberLoading(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }

    return (
        <div>
            <Toast ref={toast} />
            <header className={scrolling ? 'sticky' : ''}>
                <Nav handleLogout={handleLogout} isSuperuser={isSuperuser} userLable={userLable} image={image} userName={userName} is_leader={is_leader} />
            </header>
            <div 
            className='table-container' 
            style={{
            paddingBottom: '2rem',
            backgroundImage: `url("${isSuperuser ? adminLogo : image}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '90vh',
            position: 'relative' // Needed for overlay positioning
            }}
        >
            <div
            className='table-inner-div'
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0 // Below content
            }}
            />
            <FlexComponent>
                {!loading && <FlexComponent key={isSubmit} className='profileContainer' justifyContent='center' alignItems='start' style={{ margin: '1rem', gap: '2rem' }}  >
                    <FlexComponent className='profileViewDivs' flexDirection='column' style={{ gap: '2rem', width: '35%', zIndex: '1' }}>
                        <FlexComponent flexDirection="column" alignItems="start" className="profileViewContainer" style={{  borderRadius: '1rem' }}>
                            <FlexComponent justifyContent="start" >
                                {formList?.length > 1 && <FormSubmissionList data={formList} formId={formId} />}
                                {auditLogs?.length > 1 && <AuditLogs data={auditLogs} formId={formId} />}
                            </FlexComponent >
                            <div style={{ width: "100%", marginTop: (formList?.length > 1 || auditLogs?.length > 1) && "-75px" }} >
                                {applicantData && <Applicant data={applicantData} setIsSubmit={setIsSubmit} handleStageUpdate={handleStageUpdate} contactId={contactId} formId={formId} userId={userId} AddLeadFollowers={AddLeadFollowers} AddLeadSales={AddLeadSales} MoveLeadSalesToFollower={MoveLeadSalesToFollower} MoveLeadFollowerToSales={MoveLeadFollowerToSales} RemoveLeadFollowerSales={RemoveLeadFollowerSales} memberLoading={memberLoading} updateContactOpportunityOwner={updateContactOpportunityOwner} toast={toast} />}
                            </div>
                        </FlexComponent>
                        <WorkExperience data={workData} setIsSubmit={setIsSubmit} formId={formId} userId={userId} toast={toast} />
                        {profileData && <PersonalInformation data={profileData} setIsSubmit={setIsSubmit} formId={formId} userId={userId} toast={toast} />}
                        {addressData.length > 0 && <Address data={addressData} setIsSubmit={setIsSubmit} formId={formId} userId={userId} toast={toast} />}
                        <PropertyOwned data={propertyData} setIsSubmit={setIsSubmit} formId={formId} userId={userId} toast={toast} />
                    </FlexComponent>
                    {/* //////////////////////////////////////////////////////////////////// */}
                    <FlexComponent className='profileViewDivs' flexDirection='column' style={{ gap: '2rem', width: '50%', zIndex: '0' }}>
                        <div className='profileActivityContainer' style={{  width: "100%", borderRadius: '1rem', paddingBottom:"20px" }} >
                            <div style={{ textAlign: 'start', width: '100%', padding: '1rem 1.5rem' }}>
                                <p style={{ fontWeight: '600' }}>Activity</p>
                            </div>
                            {notes && <div style={{ maxHeight: "450px", overflowY: "auto",}} > 
                                <Activity comments={notes} usersList={usersList} formId={formId} userId={userId} section="profileView" /> 
                            </div>}
                        </div>
                        {shoppingData && <ShoppingFor data={shoppingData} setIsSubmit={setIsSubmit} formId={formId} userId={userId} toast={toast} />}
                        {typeOfHomeData && <TypeOfHome data={typeOfHomeData} setIsSubmit={setIsSubmit} formId={formId} userId={userId} toast={toast} />}
                        {savingsData && <SavingsAndAssets data={savingsData} setIsSubmit={setIsSubmit} formId={formId} userId={userId} toast={toast} />}
                        {/* {cosignerData?.do_you_have_a_cosigner && <CoSigner  data={cosignerData} setIsSubmit={setIsSubmit} formId={formId} userId={userId} toast={toast}/>} */}
                        {<CoSigner data={cosignerData.cosigners} isCosigner={cosignerData?.do_you_have_a_cosigner} setIsSubmit={setIsSubmit} formId={formId} userId={userId} toast={toast} />}
                    </FlexComponent>
                </FlexComponent>
                }
            </FlexComponent>
        </div>
        </div>
    )
}

export default ProfileView
