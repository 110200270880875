import React, { useEffect, useRef, useState } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Tag } from 'primereact/tag';
import axios from '../../utils/axios';  
import { campaignUserList, campaignUserUpdate } from '../../utils/urls';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';

export default function CampaignUserTable() {
    const {campaignId} = useParams()
    const url = `${campaignUserList}${campaignId}`
    const [products, setProducts] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        fullName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        languages: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const dt = useRef(null);

    useEffect(()=>{
        // setLoading(true);
        axios.get(url, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setProducts(response.data);
                    setLoading(false);
                    // initFilters();
                  }
                })
            .catch((error)=>{
                setProducts(null)
                
            })
    },[url, isMounted])

    // useEffect(() => {
    //     ProductService.getProductsMini().then((data) => setProducts(data));
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getSeverity = (value) => {
        switch (value) {
            case 'Active':
                return 'success';

            case 'Inactive':
                return 'danger';

            default:
                return null;
        }
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status} severity={getSeverity(rowData.status)}></Tag>;
    };


    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };

    // const campaignView = (rowData) => {
    //     const user_id = rowData.user_id
    //     return <Button className='view-button' label="Campaigns" icon="pi pi-external-link" onClick={()=>redirectUserPage(user_id)} />
    // };

    // const redirectUserPage = (user_id) =>{
    //     navigate(`/campaigns/${locationId}/${user_id}`)
    // }

      const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };
    
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className='header-container-with-csv-export'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
                <div className="flex align-items-center justify-content-end gap-2">
                    <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                </div>
            </div>
        );
    };
    
    const header = renderHeader();

    return (
        <div className="card p-fluid">
            <DataTable ref={dt} value={products} paginator rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ width: '2rem'  }} body={srNoBodyTemplate} />
                <Column field="fullName" header="Name" style={{ width: '20%' }} sortable></Column>
                <Column field="languages" header="Language" style={{ width: '20%' }} sortable></Column>
                <Column field="status" header="Status" body={statusBodyTemplate}  style={{ width: '20%' }} sortable></Column>
                <Column field="target" header="Target" style={{ width: '20%' }} sortable></Column>
                <Column field="received" header="Deliverd" style={{ width: '20%' }} sortable></Column>
                <Column field="remaining" header="Remaining" style={{ width: '20%' }} sortable></Column>
                {/* <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column> */}
                {/* <Column body={campaignView} style={{ width: '30%' }}></Column> */}
            </DataTable>
        </div>
    );
}