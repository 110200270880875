import React, { useEffect, useRef, useState } from 'react'
import GetStarted from '../../components/form/GetStarted'
import FlexComponent from '../../components/FlexComponent'
import './form.css'
import { Formik } from 'formik'
import * as yup from "yup";
import { Button } from 'primereact/button'
import WorkHistory from '../../components/form/WorkHistory'
import HowMuchMoneySaved from '../../components/form/HowMuchMoneySaved'
import YourCredit from '../../components/form/YourCredit'
import AddressHistory from '../../components/form/AddressHistory'
import ContactInformation from '../../components/form/ContactInformation'
import WhatTypeOfHome from '../../components/form/WhatTypeOfHome'
import CoSignerInfo from '../../components/form/CoSignerInfo'
import InternalDetails from '../../components/form/InternalDetails'
import axios from '../../utils/axios'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyToken, form_submit, getAvailableUsers } from '../../utils/urls'
import jwt_decode from "jwt-decode";
import { setUser } from '../../utils/userSlice'
import { RefreshToken } from '../../utils/refreshToken'
import Swal from 'sweetalert2'
import { Toast } from 'primereact/toast'
import { Nav } from '../../shared/Nav'
import { get_form_initials } from '../../utils/urls';
import logo from '../../Images/homes_sale_logo.png'
import { Avatar } from 'primereact/avatar'

const formSchema = yup.object().shape({
    firstName: yup.string().trim().required("required"),
    lastName: yup.string().trim().required("required"),
    language: yup.string().required("required"),
    work_history: yup.array().min(1, 'work history is Required').required('This field is required'),
    address_history: yup.array().min(1, 'Address is Required').required('This field is required'),
    total_amount_saved: yup.number().required("required"),
    estimated_credit_score: yup.number().required("required"),
    cell_phone_number: yup.string()
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Cell phone number must be in the format (xxx) xxx-xxx')
        .required('Cell phone number is required'),
    email: yup.string().email("invalid email").required("required"),
    lead_source: yup.string().required("required"),
    which_homes_for_sale_region: yup.string().required("required"),
    application_pipeline: yup.string().required("required"),
    application_by: yup.string().required("required"),
});

function NewDesignedForm() {
    const [locationData, setLocationData] = useState();
    const [ghlUserId, setGhlUserId] = useState(null);
    const toast = useRef(null);
    const navigate = useNavigate()
    const location = useLocation();
    const isSuperuser = useSelector((state) => state.user.isSuperuser);
    const image = useSelector((state) => state.user.image);
    const userLable = useSelector((state) => state.user.userLable);
    const userName = useSelector((state) => state.user.userName);
    const userId = useSelector((state) => state.user.userId);
    const is_leader = useSelector((state) => state.user.is_leader);
    const [scrolling, setScrolling] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [languageSelect, setLanguageSelect] = useState("")
    const [availableUsers, setAvailableUsers] = useState([])
    const [currentStep, setCurrentStep] = useState(0);

    const formSteps = [
        { id: 'getStarted', component: GetStarted, title: 'Get Started', fields: ['firstName', 'lastName', 'language'] },
        { id: 'workAndIncome', component: WorkHistory, title: 'Work & Income', fields: ['work_history'] },
        { id: 'savingsAssets', component: HowMuchMoneySaved, title: 'Savings & Assets', fields: ['total_amount_saved'] },
        { id: 'YourCredit', component: YourCredit, title: 'Credit', fields: ['estimated_credit_score'] },
        { id: 'addressId', component: AddressHistory, title: 'Address', fields: ['address_history'] },
        { id: 'contactId', component: ContactInformation, title: 'Contact', fields: ['cell_phone_number', 'email'] },
        { id: 'homeTypeId', component: WhatTypeOfHome, title: 'Home Type', fields: [] },
        { id: 'cosignerId', component: CoSignerInfo, title: 'Cosigner', fields: [] },
        { id: 'internalDetailsId', component: InternalDetails, title: 'Internal Details', fields: ['lead_source', 'which_homes_for_sale_region', 'application_pipeline', 'application_by'] },
    ];

    useEffect(() => {
        const authTokens = JSON.parse(localStorage.getItem('authTokens'))
        const refresh = authTokens?.refresh
        if (refresh) {
            const body = JSON.stringify({
                token: refresh,
            });
            axios
                .post(verifyToken, body, {
                    headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                    if (response.status === 200) {
                        const access = authTokens?.access
                        const decodedToken = jwt_decode(access)
                        dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser, userLable: decodedToken.userLable, image: decodedToken.image, userName: decodedToken.userName, ghlUserId: decodedToken.ghlUserId, is_leader: decodedToken.is_leader }));
                        localStorage.setItem("prevUrl", window.location.pathname);
                    }
                })
                .catch((err) => {
                    localStorage.removeItem('authTokens');
                    localStorage.removeItem('prevUrl');
                    dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
                    navigate('/login')
                });
        } else {
            localStorage.removeItem('authTokens');
            localStorage.removeItem('prevUrl');
            dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
            navigate('/login')
        }
    }, [isSuperuser])

    useEffect(() => {
        const intervalId = setInterval(async () => {
            await RefreshToken();
        }, 3600000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure to log out?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('authTokens');
                localStorage.removeItem('prevUrl');
                navigate('/login')
            }
        })
    }

    function changeIncomeColor(values) {
        try {
            const applicantIncome = values.work_history?.find((work) => work.status)?.yearly_income
            if (applicantIncome) {
                if (applicantIncome >= 80000) return '#2FC623';
                else if (applicantIncome < 80000 && applicantIncome >= 65000) return 'orange';
                else return '#EB4243'
            }
        } catch (error) {
            return ''
        }
    }

    function changeCreditColor(applicantCredit) {
        try {
            const credit = parseInt(applicantCredit);
            if (isNaN(credit)) return '';
            if (credit >= 620) return '#2FC623';
            else if (credit < 620 && credit >= 500) return '#F7DF01';
            else return 'red'
        } catch (error) {
            return ''
        }
    }

    function changeAvailableColor(cashAvailable) {
        try {
            if (isNaN(cashAvailable)) return '';
            if (cashAvailable >= 45000) return '#2FC623';
            else if (cashAvailable < 45000 && cashAvailable >= 20000) return '#FF9A00';
            else if (cashAvailable) return 'red'
        } catch (error) {
            return ''
        }
    }

    const initialValues = {
        firstName: "",
        middleName: "",
        lastName: "",
        language: "",
        otherLanguage: "",
        where_are_you_in_the_process: "",
        will_this_be_your_first_time: "",
        work_history: [],
        do_you_have_any_additional_source_of_income: "",
        total_amount_saved: "",
        is_any_of_it_cash_on_hand: "",
        any_additional_savings: "",
        notes_about_savings: "",
        estimated_credit_score: "",
        date_of_birth: "",
        social_security_no: "",
        status_in_country: "",
        address_history: [],
        do_you_own_any_other_property: "N",
        other_property_owned: [],
        cell_phone_number: "",
        email: "",
        best_days_and_times_to_reach: "",
        home_type: [],
        bedrooms: "",
        bathrooms: "",
        do_you_have_price_range_in_mind: "",
        why_are_you_looking_to_buy_this_time: "",
        purchase_type: "",
        notes_about_home_search: "",
        preapproved_already: "",
        preapproval_details: "",
        specific_area_interested_in: [],
        do_you_have_a_cosigner: "",
        cosigner_list: [],
        cb_other_time_at_position: "",
        lead_source: "Homes For Sale Lead",
        other_lead_source: "",
        does_buyer_name_match_original_lead: "",
        name_of_lead: "",
        cell_number_of_lead: "",
        application_pipeline: "",
        application_by: "",
        which_homes_for_sale_region: "",
        address: ""
    };

    const formatDate = (date) => {
        if (!date) return ''
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            setLoading(true);
            const { otherLanguage, other_lead_source, cb_other_time_at_position, ...data } = values
            data.form_name = "New App"
            data.language = data.language === "Other" ? otherLanguage : data.language
            data.lead_source = data.lead_source === "Other" ? other_lead_source : data.lead_source
            data.date_of_birth = formatDate(data.date_of_birth)

            data.cosigner_list = data.cosigner_list.map((cosigner) => {
                cosigner.cb_time_at_position = cosigner.cb_time_at_position === 'Other' ? cb_other_time_at_position : cosigner.cb_time_at_position;
                cosigner.cb_start_date = formatDate(cosigner.cb_start_date);
                cosigner.cb_end_date = formatDate(cosigner.cb_end_date);
                cosigner.cb_DOB = formatDate(cosigner.cb_DOB);
                cosigner.cosigner_cell_phone_number = parseInt(cosigner.cosigner_cell_phone_number?.replace(/\D/g, '')) || "";
                return cosigner;
            });
            if (data.cosigner_list.length > 0) {
                data.do_you_have_a_cosigner = "Yes"
            } else {
                data.do_you_have_a_cosigner = "No"
            }

            data.cell_phone_number = parseInt(data.cell_phone_number?.replace(/\D/g, '')) || "";
            data.cell_number_of_lead = parseInt(data.cell_number_of_lead?.replace(/\D/g, '')) || "";
            data.work_history = data.work_history.map(({ otherTimeAtPostion, start_date, end_date, ...entry }) => ({
                ...entry,
                time_at_position: entry.time_at_position === 'Other' ? otherTimeAtPostion : entry.time_at_position,
                start_date: formatDate(start_date),
                end_date: formatDate(end_date)
            }))

            const response = await axios.post(form_submit, data, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Form Submitted Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setLoading(false)
                onSubmitProps.resetForm();
            } else {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Some Error Happened",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setLoading(false)
            }
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Some Error Happened",
                showConfirmButton: false,
                timer: 1500,
            });
                setLoading(false)
            }
        };

    const getLocationList = async () => {
        if (userId) {
            try {
                const response = await axios.get(`${get_form_initials}?userId=${userId}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) {
                    const data = response.data;
                    const mergedData = [...data.location_user_data];
                    setLocationData(mergedData);
                    setGhlUserId(data.ghl_user_id)
                }
            } catch (error) {
                console.error('Error fetching location list:', error);
            }
        }
    };

    const getAvailableUsersList = async () => {
        if (languageSelect) {
            try {
                const response = await axios.get(`${getAvailableUsers}?type=Live Assignment&language=${languageSelect}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) {
                    setAvailableUsers(response.data)
                }
            } catch (error) {
                console.error('Error fetching available users:', error);
            }
        }
    };

    useEffect(() => {
        getLocationList();
    }, [get_form_initials, userId]);

    useEffect(() => {
        getAvailableUsersList()
    }, [languageSelect])

    function getCurrentYearlyIncome(values) {
        try {
            const findIncome = values.work_history?.find((work) => work.status)
            return findIncome ? findIncome.yearly_income : ''
        } catch (error) {
            return ''
        }
    }

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    function formatNumber(number) {
        if (number) {
            const num = parseInt(number, 10);
            if (isNaN(num)) return '';
            if (num >= 1000 && num < 1000000) return (num / 1000).toFixed(1) + 'k';
            else if (num >= 1000000 && num < 1000000000) return (num / 1000000).toFixed(1) + 'M';
            else if (num >= 1000000000) return (num / 1000000000).toFixed(1) + 'B';
            else return num.toString();
        }
    }

    const hasStepErrors = (stepIndex, errors) => {
        const stepFields = formSteps[stepIndex].fields;
        return stepFields.some(field => errors[field]);
    };

    const handleNext = (validateForm) => {
        validateForm().then((errors) => {
            if (currentStep < formSteps.length - 1 && !hasStepErrors(currentStep, errors)) {
                setCurrentStep(currentStep + 1);
            } else if (hasStepErrors(currentStep, errors)) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Please fill all required fields correctly",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        });
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <header style={{ background: "black" }} className={scrolling ? 'sticky' : ''}>
                <Nav handleLogout={handleLogout} isSuperuser={isSuperuser} userLable={userLable} image={image} userName={userName} is_leader={is_leader} />
            </header>
            <div style={{ padding: "2.5rem 0", background: "white", position: 'relative' }}>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={formSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        handleSubmit,
                        isSubmitting,
                        validateForm
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <FlexComponent style={{ width: "80%", padding: '1rem 0' }} alignItems="start">
                                {formSteps.map((step, index) => (
                                    <div key={step.id}>
                                        <a
                                            href={`#${step.id}`}
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                                // color: currentStep === index ? 'blue' :
                                                //     (errors[Object.keys(errors).find(key => step.component.name.toLowerCase().includes(key.split('_')[0]))] && touched[Object.keys(touched).find(key => step.component.name.toLowerCase().includes(key.split('_')[0]))]) ? 'red' : '',
                                                // fontWeight: currentStep === index ? 'bold' : 'normal'
                                                borderBottom : currentStep === index ? '5px solid black' : 'none'
                                            }}
                                            onClick={(e) => { e.preventDefault(); setCurrentStep(index); }}
                                        >
                                            {step.title}
                                        </a>
                                    </div>
                                ))}
                            </FlexComponent>
                            <FlexComponent className="formDiv" justifyContent='end' alignItems='start' gap="1rem">
                                <FlexComponent className="inputContainer" flexDirection='column' style={{ zIndex: '1', background: '#F2F2F2', width: '80%', boxShadow: '0px 4px 10px 4px rgb(0 0 0 / 20%)', borderRadius: '15px' }}>
                                    {currentStep === 0 && <GetStarted formName="New App" setLanguageSelect={setLanguageSelect} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />}
                                    {currentStep === 1 && <WorkHistory values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />}
                                    {currentStep === 2 && <HowMuchMoneySaved values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />}
                                    {currentStep === 3 && <YourCredit values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />}
                                    {currentStep === 4 && <AddressHistory values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />}
                                    {currentStep === 5 && <ContactInformation values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />}
                                    {currentStep === 6 && <WhatTypeOfHome values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} setFieldValue={setFieldValue} />}
                                    {currentStep === 7 && values.do_you_have_a_cosigner === "Yes" && <CoSignerInfo values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />}
                                    {currentStep === 8 && <InternalDetails ghlUserId={ghlUserId} locationData={locationData} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />}

                                    <FlexComponent justifyContent="space-between" style={{ padding: "1rem" }}>
                                        <Button
                                            label="Previous"
                                            onClick={handlePrevious}
                                            disabled={currentStep === 0}
                                            style={{ background: "black" }}
                                        />
                                        {currentStep === formSteps.length - 1 ? (
                                            <Button
                                                type="submit"
                                                style={{ background: "black" }}
                                                label="Submit"
                                                icon="pi pi-check"
                                                loading={loading}
                                                disabled={isSubmitting}
                                            />
                                        ) : (
                                            <Button
                                                label="Next"
                                                onClick={() => handleNext(validateForm)}
                                                style={{ background: "black" }}
                                            />
                                        )}
                                    </FlexComponent>
                                </FlexComponent>
                                <FlexComponent className="navigationClass" flexDirection="column" style={{ width: "20%", marginTop: ".2rem" }}>
                                    <FlexComponent justifyContent='start' alignItems="end" gap='.8rem' >
                                        <div>
                                            <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeIncomeColor(values), fontSize: '24px' }}>{formatNumber(getCurrentYearlyIncome(values)) || 0}</p>
                                            <p>Income</p>
                                        </div>
                                        <div>
                                            <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeCreditColor(values?.estimated_credit_score || ''), fontSize: values?.estimated_credit_score.length > 5 ? '12px' : '24px' }}>{values?.estimated_credit_score || 0}</p>
                                            <p>Credit</p>
                                        </div>
                                        <div>
                                            <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeAvailableColor(values?.total_amount_saved || ''), fontSize: '24px' }}>{formatNumber(values?.total_amount_saved) || 0}</p>
                                            <p>Available</p>
                                        </div>
                                    </FlexComponent>
                                    <FlexComponent style={{ width: "100%", padding: '1rem 0' }} flexDirection="column" alignItems="start">
                                        {formSteps.map((step, index) => (
                                            <div key={step.id}>
                                                {(step.title === 'Work & Income' && (changeIncomeColor(values) === "#EB4243" || changeIncomeColor(values) === "orange")) && (
                                                    <p style={{ borderRadius: "5px", color: "blue", fontSize: "10px", border: "1px solid grey", padding: "3px" }}>
                                                        Make sure you ask questions about any additional sources of income, and about a cosigner to help strengthen this application
                                                    </p>
                                                )}
                                                {(step.title === 'Savings & Assets' && (changeAvailableColor(values?.total_amount_saved) === "red" || changeAvailableColor(values?.total_amount_saved) === "#FF9A00")) && (
                                                    <p style={{ borderRadius: "5px", color: "blue", fontSize: "10px", border: "1px solid grey", padding: "3px" }}>
                                                        Please make sure to ask all of the questions about savings including do they have any family friends or relatives who has ever suggested they would be willing to help with a gift of cash towards your down payment or closing costs, and dont forget to ask about any retirement accounts, savings accounts, or investments they may have.
                                                    </p>
                                                )}
                                            </div>
                                        ))}
                                    </FlexComponent>
                                    {availableUsers.length > 0 && (
                                        <div>
                                            <h3>Available Users</h3>
                                            {availableUsers.map((user) => (
                                                <FlexComponent style={{ marginTop: ".5rem" }} justifyContent="start" key={user.id}>
                                                    <Avatar image={user.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(user?.name)}</span>} shape="circle" style={{ marginRight: '8px' }} />
                                                    {user.name}
                                                </FlexComponent>
                                            ))}
                                        </div>
                                    )}
                                </FlexComponent>
                            </FlexComponent>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default NewDesignedForm